<template>
    <div>
        <DxChart id="brakeChart"
                 :data-source="dt">
            <DxCommonSeriesSettings :point="{ visible: true, size: 6 }"
                                    argument-field="s" 
                                    type="line" />
            <DxCommonAxisSettings>
                <DxGrid :visible="true" />
            </DxCommonAxisSettings>
            <DxCommonPaneSettings :border="{ visible: true }" />
            <DxSeries v-for="l in lt"
                      :key="l.value"
                      :value-field="l.value"
                      :name="l.name"
                      :color="l.color" />
            <DxArgumentAxis :visible="false"
                            :max-value-margin="0.1"
                            title="s [m]">
                <DxLabel :visible="true"></DxLabel>
                <DxTick :visible="false"></DxTick>
            </DxArgumentAxis>
            <DxValueAxis :visible="false"
                         :max-value-margin="0.1"
                         title="v [m/s]">
                <DxLabel :visible="true"></DxLabel>
                <DxTick :visible="false"></DxTick>
            </DxValueAxis>
            <DxTooltip :enabled="true" 
                       :zIndex="1002"
                      :customize-tooltip="customizeTooltip"/>
            <DxLegend :visible="false" />
        </DxChart>
    </div>
</template>
<script>
    import {
        DxChart,
        DxSeries,
        DxArgumentAxis,
        DxCommonSeriesSettings,
        DxCommonAxisSettings,
        DxGrid,
        DxLegend,
        DxLabel,
        DxFormat,
        DxTick,
        DxValueAxis,
        DxCommonPaneSettings,
        DxTooltip
    } from "devextreme-vue/chart";
    export default {
        name: "Chart",
        props: ["dt", "lt"],
        components: {
            DxChart,
            DxSeries,
            DxArgumentAxis,
            DxCommonSeriesSettings,
            DxCommonAxisSettings,
            DxGrid,
            DxLegend,
            DxLabel,
            DxFormat,
            DxTick,
            DxValueAxis,
            DxCommonPaneSettings,
            DxTooltip
        },
        methods: {
            customizeTooltip(point) {
                return {
                    text: 'v: ' + point.valueText + ' m/s<br/>' + 's: ' + point.argumentText + ' m'
                }
            }
        }
    };
</script>