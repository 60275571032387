<template>
    <div style="overflow-x:auto !important">
        <div>
            <span class="my_fontSize_1Point5 my_color_00338e my_fontWeight_600 ZAfont_family">{{$t('3091')}} </span>
            <span v-if="inverter_Selection_Shown==true">
                <span v-if="inverter_Filter_Shown==true" @click="inverter_Filter_Shown=false" class="fas fa-filter my_color_00338e my_fontSize_1Point3"></span>
                <span v-if="inverter_Filter_Shown==false" @click="inverter_Filter_Shown=true" class="fal fa-filter my_color_00338e my_fontSize_1Point3"></span>
            </span>
            <button v-if="inverter_Selection_Shown==false" @click="inverter_Selection_Shown=true" class="btn my_btn_changeSelection">{{$t('3182')}}</button>
        </div>
        <div v-bind:class="{my_grid_filter: ((inverter_Selection_Shown==true) && (inverter_Filter_Shown==true))}">
            <div v-if="((inverter_Selection_Shown==true) && (inverter_Filter_Shown==true))">
                <div class="my_filter">
                    <ul class="my_inverter_filter">
                        <li class="ZAfont_family ZAfont_size" style="width:100%; background-color:#00338e; color:#ffffff">{{$t('3092')}}</li>
                    </ul>
                    <div>
                        <div>
                            <div v-if="inverter_FilterCriterion_inverter_Series_Selection.length>0 || inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet.length>0">
                                <hr style="margin-top:0; margin-bottom:0" />
                                <div class="my_fontSize_1 my_fontWeight_600 ZAfont_family" style="margin-top:5px">{{$t('1466')}}</div>
                                <ul style="list-style:none; padding-top:5px; padding-left:0px; padding-right:0px; padding-bottom:0px; margin:0" class="ZAfont_family ZAfont_size">
                                    <li v-for="inverter_FilterCriterion_inverter_Series in inverter_FilterCriterion_inverter_Series_Selection">
                                        <div style="display:grid; grid-template-columns:22px auto;">
                                            <span>
                                                <input type="checkbox" @input="change_Selected_atElementOf_inverter_FilterCriterion_inverter_Series_Selection(inverter_FilterCriterion_inverter_Series.series)" v-bind:checked="inverter_FilterCriterion_inverter_Series.selected" />
                                            </span>
                                            <span> {{inverter_FilterCriterion_inverter_Series.series}}</span>
                                        </div>
                                    </li>
                                </ul>
                                <div class="my_fontSize_1 my_fontWeight_600 ZAfont_family" style="margin-top: 10px">{{$t('1467')}}</div>
                                <ul style="list-style:none; padding-top:5px; padding-left:0px; padding-right:0px; padding-bottom:0px; margin:0" class="ZAfont_family ZAfont_size">
                                    <li v-for="inverter_FilterCriterion_inverter_Series_MountingControlCabinet in inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet">
                                        <div style="display:grid; grid-template-columns:22px auto;">
                                            <span>
                                                <input type="checkbox" @input="change_Selected_atElementOf_inverter_FilterCriterion_inverter_Series_Selection(inverter_FilterCriterion_inverter_Series_MountingControlCabinet.series)" v-bind:checked="inverter_FilterCriterion_inverter_Series_MountingControlCabinet.selected" />
                                            </span>
                                            <span> {{inverter_FilterCriterion_inverter_Series_MountingControlCabinet.series}}</span>
                                        </div>
                                    </li>
                                </ul>
                                <div style="display:grid; grid-template-columns:22px auto; margin-top: 10px;">
                                    <a @click="change_Selected_at_thirdPartyInverter" class="ZAfont_family ZAfont_size" href="#">{{$t('3261')}}</a>
                                </div>
                            </div>
                            <div v-if="inverter_FilterCriterion_inverter_Series_Selection.length==0 && inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet.length==0" class="ZAfont_family ZAfont_size">{{$t('3031')}}.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div v-if="inverter_Selection_Shown==true">
                    <div v-if="inverter_Selection_Filtered==true">
                        <button @click="remove_allElementsAt_arrayof_active_inverter_FilterCriteria" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#00338e">
                            <span class="ZAfont_family ZAfont_size" style="color:#ffffff">{{$t('3043')}}</span>
                        </button>
                        <span v-for="filterCriterion in arrayOf_active_inverter_FilterCriteria">
                            <button v-if="filterCriterion.filterBy == 'inverter_Series'" @click="change_Selected_atElementOf_inverter_FilterCriterion_inverter_Series_Selection(filterCriterion.data)" class="btn" style="border:1px solid #00338e; margin-right:8px; margin-bottom:8px; background-color:#ffffff">
                                <span class="ZAfont_family ZAfont_size my_color_00338e">{{filterCriterion.data}}</span>
                                <span class="fal fa-times fa-lg my_color_00338e" style="margin-left:5px; font-weight:600; color:#00338e"></span>
                            </button>
                        </span>
                    </div>
                    <div v-if="(((inverter_Selection.length==0)&&(inverter_Selection_Filtered==false))||(inverter_Selection.length>0))">
                        <div class="ZAfont_family my_fontSize_1Point1 my_font-weight_700">{{$t('3267')}}</div>
                        <div v-if="inverter_Selection.length>0" class="ZAfont_family ZAfont_size">
                            <div v-if="inverter_Selection_Filtered==false">
                                <div v-if="inverter_Selection_fullLength==1">{{$t('3094', {show: 1, max: 1})}}</div>
                                <div v-if="((inverter_Selection_fullLength>1) && (inverter_Selection_fullLength<=5))">{{$t('3094', {show: inverter_Selection_fullLength, max: inverter_Selection_fullLength})}}</div>
                                <div v-if="((inverter_Selection_fullLength>5) && (inverter_Selection_lastIndex<inverter_Selection_fullLength))">{{$t('3094', {show: '1 - ' + inverter_Selection_lastIndex, max: inverter_Selection_fullLength})}}</div>
                                <div v-if="((inverter_Selection_fullLength>5) && (inverter_Selection_lastIndex>=inverter_Selection_fullLength))">{{$t('3094', {show: '1 - ' + inverter_Selection_fullLength, max: inverter_Selection_fullLength})}}</div>
                            </div>
                            <div v-if="inverter_Selection_Filtered==true">
                                <div v-if="filtered_inverter_Selection.length == inverter_Selection_fullLength">
                                    <div v-if="inverter_Selection_fullLength==1">{{$t('3094', {show: 1, max: 1})}}</div>
                                    <div v-if="((inverter_Selection_fullLength>1) && (inverter_Selection_fullLength<=5))">{{$t('3094', {show: inverter_Selection_fullLength, max: inverter_Selection_fullLength})}}</div>
                                    <div v-if="((inverter_Selection_fullLength>5) && (inverter_Selection_lastIndex<inverter_Selection_fullLength))">{{$t('3094', {show: '1 - ' + inverter_Selection_lastIndex, max: inverter_Selection_fullLength})}}</div>
                                    <div v-if="((inverter_Selection_fullLength>5) && (inverter_Selection_lastIndex>=inverter_Selection_fullLength))">{{$t('3094', {show: '1 - ' + inverter_Selection.length, max: inverter_Selection_fullLength})}}</div>
                                </div>
                                <div v-if="filtered_inverter_Selection.length < inverter_Selection_fullLength">
                                    <div v-if="filtered_inverter_Selection.length==1">{{$t('3094', {show: 1, max: 1})}} | {{$t('3095', {total: inverter_Selection_fullLength})}}</div>
                                    <div v-if="((filtered_inverter_Selection.length>1) && (filtered_inverter_Selection.length<=5))">{{$t('3094', {show: filtered_inverter_Selection.length, max: filtered_inverter_Selection.length})}} | {{$t('3095', {total: inverter_Selection_fullLength})}}</div>
                                    <div v-if="((filtered_inverter_Selection.length>5) && (inverter_Selection_lastIndex<filtered_inverter_Selection.length))">{{$t('3094', {show: '1 - ' + inverter_Selection_lastIndex, max: filtered_inverter_Selection.length})}} | {{$t('3095', {total: inverter_Selection_fullLength})}}</div>
                                    <div v-if="((filtered_inverter_Selection.length>5) && (inverter_Selection_lastIndex>=filtered_inverter_Selection.length))">{{$t('3094', {show: '1 - ' + filtered_inverter_Selection.length, max: filtered_inverter_Selection.length})}} | {{$t('3095', {total: inverter_Selection_fullLength})}}</div>
                                </div>
                            </div>
                        </div>
                        <ul class="my_elevatorMotor_Machine">
                            <li v-for="inverter in inverter_Selection" v-bind:class="{selected: inverter.selected==true}">
                                <div class="my_div_width1500" style="display:grid; grid-template-columns: auto 350px; grid-template-rows:100%">
                                    <div style="cursor: pointer; border: 1px solid #ced2d8; display: grid;" @click="change_Selected_at_inverter_Selection(inverter)">
                                        <div style="display:grid; grid-template-columns: auto auto auto auto auto auto; grid-template-rows:auto auto; padding:9px; overflow: auto">
                                            <div style="grid-column: 1 / span 4">
                                                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point1 ZAfont_family">{{inverter.inverter.typeDesignation}}</div>
                                            </div>
                                            <div class="width1000" style="grid-column: 1 / span 1;">
                                                <Images :imageFilePath="inverter.imagePath" :maxImageHeight="90" :maxImageWidth="150"></Images>
                                            </div>
                                            <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{inverter.inverter.nominalCurrent}}</span> A</div>
                                                <div class="my_table_parameters_infoText">{{$t('1381')}}</div>
                                            </div>
                                            <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(inverter.inverter.maximumOperatingCurrent)}}</span> A</div>
                                                <div class="my_table_parameters_infoText">{{$t('3183')}}</div>
                                            </div>
                                            <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(inverter.inverter.acceleration)}}</span> m/s²</div>
                                                <div class="my_table_parameters_infoText">{{$t('3184')}}</div>
                                            </div>
                                            <div v-if="inverter.inverter.utilization != null" class="my_div_minWidth_100 my_div_maginleft_5">
                                                <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{inverter.inverter.utilization}} %</span></div>
                                                <div class="my_table_parameters_infoText"><span>{{$t('1093')}}</span><span v-if="inverter.inverter.utilization_LimitValue"> max. {{inverter.inverter.utilization_LimitValue}} %</span></div>
                                            </div>
                                            <div v-if="inverter.showMaximumShortCircuitCurrent == true" class="my_div_minWidth_100 my_div_maginleft_5">
                                                <div class="my_fontSize_1Point4"><span class="my_font-weight_600"><span v-if="inverter.maximumShortCircuitCurrent_OK == true">{{inverter.maximumShortCircuitCurrent}} A</span><span v-if="inverter.maximumShortCircuitCurrent_OK == false" style="color: red">{{inverter.maximumShortCircuitCurrent}} A</span></span></div>
                                                <div class="my_table_parameters_infoText"><span>IKmax</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="background-color: #f5f5f5; border: 1px solid #ced2d8; display: grid; margin-left: 10px">
                                        <div style="display:flex; flex-direction:column; justify-content:space-between; align-items:center; padding :8px; height:100%">
                                            <div style="text-align:center">
                                                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point1 ZAfont_family">{{$t('3096')}}</div>
                                            </div>
                                            <div style="text-align:center; margin-top:5px;margin-bottom:5px">
                                                <div v-if="inverter.unintendedCarMovementCheck_Available == false">
                                                    <div class="ZAfont_family ZAfont_size">{{$t('3193')}}</div>
                                                </div>
                                                <div v-if="inverter.unintendedCarMovementCheck_Available==true">
                                                    <div v-if="inverter.unintendedCarMovementCheck_Executed==false">
                                                        <div class="ZAfont_family ZAfont_size"></div>
                                                    </div>
                                                    <div v-if="inverter.unintendedCarMovementCheck_Executed==true">
                                                        <div class="my_fontWeight_600 my_fontSize_1Point1 ZAfont_family"> {{$t('3185')}}</div>
                                                        <div v-if="inverter.unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking==false" class="ZAfont_family ZAfont_size">
                                                            <div><span class="my_fontSize_1Point5 my_fontWeight_600">{{ValueDecimalSeparator(inverter.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Distance)}}</span> m</div>
                                                        </div>
                                                        <div v-if="inverter.unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking==true" class="ZAfont_family ZAfont_size">
                                                            <div style="display:flex; justify-content:center">
                                                                <div style="flex:1; text-align:right; padding-right:0.75rem">
                                                                    <div><span class="my_fontSize_1Point5 my_fontWeight_600">{{ValueDecimalSeparator(inverter.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Distance)}}</span> m</div>
                                                                    <div>{{$t('1407')}}</div>
                                                                </div>
                                                                <div style="flex:1; text-align:left; padding-left:0.75rem; border-left:2px solid #000000">
                                                                    <div><span class="my_fontSize_1Point5 my_fontWeight_600">{{ValueDecimalSeparator(inverter.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Distance)}}</span> m</div>
                                                                    <div>{{$t('188')}}</div>
                                                                </div>
                                                            </div>
                                                            <div class="ZAfont_family ZAfont_size">{{$t('3186')}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="width:100%; text-align:center;">
                                                <div v-if="inverter.unintendedCarMovementCheck_Available == true">
                                                    <div v-if="inverter.unintendedCarMovementCheck_Executed == false">
                                                        <button @click="get_unintendedCarMovementCheck_Informations_for_inverter(inverter)" class="btn my_btn_standard" style="width:100%">{{$t('3097')}}</button>
                                                    </div>
                                                    <div v-if="inverter.unintendedCarMovementCheck_Executed == true">
                                                        <div><a @click="get_unintendedCarMovementCheck_Data_fromItemOf_inverter_Selection(inverter)" class="ZAfont_family ZAfont_size" href="#">{{$t('3098')}}</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul v-if="((inverter_Selection.length==0)&&(inverter_Selection_Filtered==false))" class="my_elevatorMotor_Machine">
                            <li>
                                <div class="my_div_width1500" style="display:grid; grid-template-columns: auto 350px; grid-template-rows:100%">
                                    <div style="cursor: pointer; border: 1px solid #ced2d8; display: grid" @click="change_Selected_at_thirdPartyInverter">
                                        <div style="display:grid; grid-template-columns: auto auto auto auto; grid-template-rows:auto auto; padding:9px; overflow: auto">
                                            <div style="grid-column: 1 / span 4">
                                                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point1 ZAfont_family">{{$t('3261')}}</div>
                                            </div>
                                            <div class="width1000" style="grid-column: 1 / span 1;">
                                                <Images :imageFilePath="thirdPartyInverter.imagePath" :maxImageHeight="90" :maxImageWidth="150"></Images>
                                            </div>
                                            <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{thirdPartyInverter.inverter.nominalCurrent}}</span> A</div>
                                                <div class="my_table_parameters_infoText">{{$t('1381')}}</div>
                                            </div>
                                            <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(thirdPartyInverter.inverter.maximumOperatingCurrent)}}</span> A</div>
                                                <div class="my_table_parameters_infoText">{{$t('3183')}}</div>
                                            </div>
                                            <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(thirdPartyInverter.inverter.acceleration)}}</span> m/s²</div>
                                                <div class="my_table_parameters_infoText">{{$t('3184')}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="background-color:#f5f5f5; border: 1px solid #ced2d8; display: grid; margin-left: 10px">
                                        <div style="display:flex; flex-direction:column; justify-content:space-between; align-items:center; padding :8px; height:100%">
                                            <div style="text-align:center">
                                                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point1 ZAfont_family">{{$t('3096')}}</div>
                                            </div>
                                            <div style="text-align:center; margin-top:5px;margin-bottom:5px">
                                                <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Available==false">
                                                    <div class="ZAfont_family ZAfont_size">{{$t('3193')}}</div>
                                                </div>
                                                <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Available==true">
                                                    <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Executed==false">
                                                        <div class="ZAfont_family ZAfont_size"></div>
                                                    </div>
                                                    <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Executed==true">
                                                        <div class="my_fontWeight_600 my_fontSize_1Point1 ZAfont_family"> {{$t('3185')}}</div>
                                                        <div v-if="thirdPartyInverter.unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking==false" class="ZAfont_family ZAfont_size">
                                                            <div><span class="my_fontSize_1Point5 my_fontWeight_600">{{ValueDecimalSeparator(thirdPartyInverter.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Distance)}}</span> m</div>
                                                        </div>
                                                        <div v-if="thirdPartyInverter.unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking==true" class="ZAfont_family ZAfont_size">
                                                            <div style="display:flex; justify-content:center">
                                                                <div style="flex:1; text-align:right; padding-right:0.75rem">
                                                                    <div><span class="my_fontSize_1Point5 my_fontWeight_600">{{ValueDecimalSeparator(thirdPartyInverter.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Distance)}}</span> m</div>
                                                                    <div>{{$t('1407')}}</div>
                                                                </div>
                                                                <div style="flex:1; text-align:left; padding-left:0.75rem; border-left:2px solid #000000">
                                                                    <div><span class="my_fontSize_1Point5 my_fontWeight_600">{{ValueDecimalSeparator(thirdPartyInverter.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Distance)}}</span> m</div>
                                                                    <div>{{$t('188')}}</div>
                                                                </div>
                                                            </div>
                                                            <div class="ZAfont_family ZAfont_size">{{$t('3186')}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="width:100%; text-align:center;">
                                                <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Available == true">
                                                    <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Executed == false">
                                                        <button @click="get_unintendedCarMovementCheck_Informations_for_thirdPartyInverter" class="btn my_btn_standard" style="width:100%">{{$t('3097')}}</button>
                                                    </div>
                                                    <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Executed == true">
                                                        <div><a @click="get_unintendedCarMovementCheck_Data_fromItemOf_thirdPartyInverter" class="ZAfont_family ZAfont_size" href="#">{{$t('3098')}}</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div v-if="inverter_Selection.length>0" style="margin-top:15px">
                            <div v-if="inverter_Selection_Filtered == false">
                                <button v-if="inverter_Selection_lastIndex<inverter_Selection_fullLength" @click="show_more_inverters_fromSelection" type="button" class="btn my_btn_standard_blue" style="margin-right:15px">{{$t('3099')}}</button>
                                <button v-if="inverter_Selection_lastIndex<inverter_Selection_fullLength" @click="show_full_inverter_Selection" type="button" class="btn my_btn_standard" style="margin-right:15px">{{$t('3100')}}</button>
                                <button v-if="inverter_Selection_lastIndex>5" @click="show_less_inverters_fromSelection" type="button" class="btn my_btn_standard">{{$t('3101')}}</button>
                            </div>
                            <div v-if="inverter_Selection_Filtered == true">
                                <button v-if="inverter_Selection_lastIndex<filtered_inverter_Selection.length" @click="show_more_inverters_fromSelection" type="button" class="btn my_btn_standard_blue" style="margin-right:15px">{{$t('3099')}}</button>
                                <button v-if="inverter_Selection_lastIndex<filtered_inverter_Selection.length" @click="show_full_inverter_Selection" type="button" class="btn my_btn_standard" style="margin-right:15px">{{$t('3100')}}</button>
                                <button v-if="inverter_Selection_lastIndex>5" @click="show_less_inverters_fromSelection" type="button" class="btn my_btn_standard">{{$t('3101')}}</button>
                            </div>
                        </div>
                        <div v-if="inverter_Selection.length>0" style="margin-top:15px">
                            <a @click="change_Selected_at_thirdPartyInverter" class="ZAfont_family ZAfont_size" href="#">{{$t('3276')}}</a>
                        </div>
                    </div>
                    <div v-if="((inverter_Selection.length==0)&&(inverter_Selection_Filtered==true))">
                        <div style="text-align:center; margin-top: 60px">
                            <span style="font-size:5rem; color:#00338e"><i class="fal fa-info-square"></i></span>
                            <div class="ZAfont_family ZAfont_size">{{$t('3063')}}</div>
                            <div class="ZAfont_family ZAfont_size" style="margin-top:20px">{{$('3257',this.$store.state.controlTechnologies.inverter_Selection.length)}}.</div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3065')}}</div>
                            <button @click="remove_allElementsAt_arrayof_active_inverter_FilterCriteria" type="button" class="my_btn_standard_blue" style="margin-top:20px">{{$t('3066')}}</button>
                        </div>
                    </div>
                </div>
                <div v-if="((inverter_Selection_Shown==false)&&(((inverter_Selected == true)&&(thirdPartyInverter_Selected == false))||((inverter_Selected==false)&&(thirdPartyInverter_Selected == true))))">
                    <ul style="list-style:none; margin:0; padding:0">
                        <li style="display:block; border: 2px solid blue;">
                            <div v-if="(inverter_Selected == true)&&(thirdPartyInverter_Selected == false)" class="my_div_width1500" style="display:grid; grid-template-columns: auto 350px; grid-template-rows:100%">
                                <div style="display: grid">
                                    <div style="display:grid; border: 1px solid #ced2d8; grid-template-columns: auto auto auto auto auto auto auto; grid-template-rows:auto auto; padding:9px; overflow: auto">
                                        <div style="grid-column: 1 / span 4">
                                            <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point1 ZAfont_family">{{selected_inverter.inverter.typeDesignation}}</div>
                                        </div>
                                        <div class="width1000" style="grid-column: 1 / span 1;">
                                            <Images :imageFilePath="selected_inverter.imagePath" :maxImageHeight="90" :maxImageWidth="150"></Images>
                                        </div>
                                        <div class="my_div_minWidth_100 my_div_maginleft_5">
                                            <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{selected_inverter.inverter.nominalCurrent}}</span> A</div>
                                            <div class="my_table_parameters_infoText">{{$t('1381')}}</div>
                                        </div>
                                        <div class="my_div_minWidth_100 my_div_maginleft_5">
                                            <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(selected_inverter.inverter.maximumOperatingCurrent)}}</span> A</div>
                                            <div class="my_table_parameters_infoText">{{$t('3183')}}</div>
                                        </div>
                                        <div class="my_div_minWidth_100 my_div_maginleft_5">
                                            <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(selected_inverter.inverter.acceleration)}}</span> m/s²</div>
                                            <div class="my_table_parameters_infoText">{{$t('3184')}}</div>
                                        </div>
                                        <div v-if="selected_inverter.inverter.utilization != null" class="my_div_minWidth_100 my_div_maginleft_5">
                                            <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{selected_inverter.inverter.utilization}} %</span></div>
                                            <div class="my_table_parameters_infoText"><span>{{$t('1093')}}</span><span v-if="selected_inverter.inverter.utilization_LimitValue"> max. {{selected_inverter.inverter.utilization_LimitValue}} %</span></div>
                                        </div>
                                        <div v-if="selected_inverter.showMaximumShortCircuitCurrent == true" class="my_div_minWidth_100 my_div_maginleft_5">
                                            <div class="my_fontSize_1Point4"><span class="my_font-weight_600"><span v-if="selected_inverter.maximumShortCircuitCurrent_OK == true">{{selected_inverter.maximumShortCircuitCurrent}} A</span><span v-if="selected_inverter.maximumShortCircuitCurrent_OK == false" style="color:red">{{selected_inverter.maximumShortCircuitCurrent}} A</span></span></div>
                                            <div class="my_table_parameters_infoText"><span>IKmax</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div style="background-color:#f5f5f5; border: 1px solid #ced2d8; display: grid; margin-left: 10px">
                                    <div style="display:flex; flex-direction:column; justify-content:space-between; align-items:center;padding :8px; height:100%">
                                        <div style="text-align:center">
                                            <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point1 ZAfont_family">{{$t('3096')}}</div>
                                        </div>
                                        <div style="text-align:center; margin-top:5px; margin-bottom:5px">
                                            <div v-if="selected_inverter.unintendedCarMovementCheck_Available==false">
                                                <div class="ZAfont_family ZAfont_size">{{$t('3193')}}</div>
                                            </div>
                                            <div v-if="selected_inverter.unintendedCarMovementCheck_Available==true">
                                                <div v-if="selected_inverter.unintendedCarMovementCheck_Executed==false">
                                                    <div class="ZAfont_family ZAfont_size"></div>
                                                </div>
                                                <div v-if="selected_inverter.unintendedCarMovementCheck_Executed==true">
                                                    <div class="my_fontWeight_600 my_fontSize_1Point1 ZAfont_family">{{$t('3185')}}</div>
                                                    <div v-if="selected_inverter.unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking==false" class="ZAfont_family ZAfont_size">
                                                        <div><span class="my_fontSize_1Point5 my_fontWeight_600">{{ValueDecimalSeparator(selected_inverter.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Distance)}}</span> m</div>
                                                    </div>
                                                    <div v-if="selected_inverter.unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking==true" class="ZAfont_family ZAfont_size">
                                                        <div style="display:flex; justify-content:center">
                                                            <div style="flex:1; text-align:right; padding-right:0.75rem">
                                                                <div><span class="my_fontSize_1Point5 my_fontWeight_600">{{ValueDecimalSeparator(selected_inverter.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Distance)}}</span> m</div>
                                                                <div>{{$t('1407')}}</div>
                                                            </div>
                                                            <div style="flex:1; text-align:left; padding-left:0.75rem; border-left:2px solid #000000">
                                                                <div><span class="my_fontSize_1Point5 my_fontWeight_600">{{ValueDecimalSeparator(selected_inverter.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Distance)}}</span> m</div>
                                                                <div>{{$t('188')}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="ZAfont_family ZAfont_size">{{$t('3186')}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width:100%; text-align:center;">
                                            <div v-if="selected_inverter.unintendedCarMovementCheck_Available == true">
                                                <div v-if="selected_inverter.unintendedCarMovementCheck_Executed == false">
                                                    <button @click="get_unintendedCarMovementCheck_Informations_for_inverter(selected_inverter)" class="btn my_btn_standard" style="width:100%">{{$t('3097')}}</button>
                                                </div>
                                                <div v-if="selected_inverter.unintendedCarMovementCheck_Executed == true">
                                                    <div><a @click="get_unintendedCarMovementCheck_Data_fromItemOf_inverter_Selection(selected_inverter)" class="ZAfont_family ZAfont_size" href="#">{{$t('3098')}}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="(inverter_Selected==false)&&(thirdPartyInverter_Selected == true)" class="my_div_width1500" style="display:grid; grid-template-columns: auto 350px; grid-template-rows:100%">
                                <div style="border: 1px solid #ced2d8; display: grid;">
                                    <div style="display:grid; grid-template-columns: auto auto auto auto; grid-template-rows:auto auto; padding:9px; overflow: auto">
                                        <div style="grid-column: 1 / span 4">
                                            <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point1 ZAfont_family">{{$t('3261')}}</div>
                                        </div>
                                        <div class="width1000" style="grid-column: 1 / span 1;">
                                            <Images :imageFilePath="thirdPartyInverter.imagePath" :maxImageHeight="90" :maxImageWidth="150"></Images>
                                        </div>
                                        <div class="my_div_minWidth_100 my_div_maginleft_5">
                                            <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{thirdPartyInverter.inverter.nominalCurrent}}</span> A</div>
                                            <div class="my_table_parameters_infoText">{{$t('1381')}}</div>
                                        </div>
                                        <div class="my_div_minWidth_100 my_div_maginleft_5">
                                            <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(thirdPartyInverter.inverter.maximumOperatingCurrent)}}</span> A</div>
                                            <div class="my_table_parameters_infoText">{{$t('3183')}}</div>
                                        </div>
                                        <div class="my_div_minWidth_100 my_div_maginleft_5">
                                            <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(thirdPartyInverter.inverter.acceleration)}}</span> m/s²</div>
                                            <div class="my_table_parameters_infoText">{{$t('3184')}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div style="background-color:#f5f5f5; border: 1px solid #ced2d8; display: grid; margin-left: 10px">
                                    <div style="display:flex; flex-direction:column; justify-content:space-between; align-items:center;padding :8px; height:100%">
                                        <div style="text-align:center">
                                            <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point1 ZAfont_family">{{$t('3096')}}</div>
                                        </div>
                                        <div style="text-align:center; margin-top:5px; margin-bottom:5px">
                                            <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Available == false">
                                                <div class="ZAfont_family ZAfont_size">{{$t('3193')}}</div>
                                            </div>
                                            <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Available==true">
                                                <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Available==true">
                                                    <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Executed==false">
                                                        <div class="ZAfont_family ZAfont_size"></div>
                                                    </div>
                                                    <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Executed==true">
                                                        <div class="my_fontWeight_600 my_fontSize_1Point1 ZAfont_family">{{$t('3185')}}</div>
                                                        <div v-if="thirdPartyInverter.unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking==false" class="ZAfont_family ZAfont_size">
                                                            <div><span class="my_fontSize_1Point5 my_fontWeight_600">{{ValueDecimalSeparator(thirdPartyInverter.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Distance)}}</span> m</div>
                                                        </div>
                                                        <div v-if="thirdPartyInverter.unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking==true" class="ZAfont_family ZAfont_size">
                                                            <div style="display:flex; justify-content:center">
                                                                <div style="flex:1; text-align:right; padding-right:0.75rem">
                                                                    <div><span class="my_fontSize_1Point5 my_fontWeight_600">{{ValueDecimalSeparator(thirdPartyInverter.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Distance)}}</span> m</div>
                                                                    <div>{{$t('1407')}}</div>
                                                                </div>
                                                                <div style="flex:1; text-align:left; padding-left:0.75rem; border-left:2px solid #000000">
                                                                    <div><span class="my_fontSize_1Point5 my_fontWeight_600">{{ValueDecimalSeparator(thirdPartyInverter.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Distance)}}</span> m</div>
                                                                    <div>{{$t('188')}}</div>
                                                                </div>
                                                            </div>
                                                            <div class="ZAfont_family ZAfont_size">{{$t('3186')}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width:100%; text-align:center;">
                                            <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Executed==false">
                                                <button @click="get_unintendedCarMovementCheck_Informations_for_thirdPartyInverter" class="btn my_btn_standard" style="width:100%">{{$t('3097')}}</button>
                                            </div>
                                            <div v-if="thirdPartyInverter.unintendedCarMovementCheck_Executed==true">
                                                <div><a @click="get_unintendedCarMovementCheck_Data_fromItemOf_thirdPartyInverter" class="ZAfont_family ZAfont_size" href="#">{{$t('3098')}}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-if="(inverter_Selection_Shown==false) && (thirdPartyInverter_Selected==false)&&(loaderModal_Shown==false)">
            <div v-if="((configurationSteps.brakeResistor.available==true)||(configurationSteps.powerRecuperationUnit.available==true))" style="margin-top:25px">
                <div v-if="((configurationSteps.brakeResistor.available==true)&&(configurationSteps.powerRecuperationUnit.available==true))">
                    <div class="my_fontSize_1Point5 my_color_00338e my_fontWeight_600 ZAfont_family">{{$t('3171')}}</div>
                    <div class="ZAfont_family ZAfont_size">{{$t('3103')}}</div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr; margin-top: 10px;">
                    <div class="my_brakeResistor_Column">
                        <span class="my_fontSize_1Point5 my_color_00338e ZAfont_family" style="grid-column: 1 / span 2;">{{$t('3187')}}</span>
                        <div v-if="configurationSteps.brakeResistor.available==true" style="margin: 5px 0px 0px 0px;">
                            <div style="margin: auto 10px auto 10px; width: auto; min-height: 50px"> </div>
                            <ul class="my_brakeResistor my_fontSize_1Point1 my_fontWeight_600">
                                <li style="cursor: pointer" v-for="brakeResistor in brakeResistor_Selection" @click="change_Selected_at_brakeResistor_Selection(brakeResistor)" v-bind:class="{active: brakeResistor.selected==true}">
                                    <!--<div style="display: flex; flex-wrap: wrap; justify-content: space-around; text-align: left">-->
                                    <div style="display:grid; grid-template-columns: 100px auto; grid-template-rows:auto auto; justify-items:left">
                                        <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point1 ZAfont_family" style="grid-column: 1 / span 2">{{brakeResistor.brakeResistor.typeDesignation}}</div>
                                        <div>
                                            <Images :imageFilePath="brakeResistor.imagePath" :maxImageHeight="90" :maxImageWidth="150"></Images>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div v-if="(configurationSteps.brakeResistor.available==true)">
                            <a v-if="full_brakeResistor_Selection_Shown==false" @click.prevent="full_brakeResistor_Selection_Shown=true" class="ZAfont_family ZAfont_size" href="#">{{$t('3252')}}</a>
                            <a v-if="full_brakeResistor_Selection_Shown==true" @click.prevent="full_brakeResistor_Selection_Shown=false" class="ZAfont_family ZAfont_size" href="#">{{$t('3253')}}</a>
                        </div>
                        <div v-if="configurationSteps.brakeResistor.available==false" class="ZAfont_family ZAfont_size">{{$t('3271')}}</div>
                    </div>
                    <div class="my_powerRecuperationUnit_Column">
                        <span class="my_fontSize_1Point5 my_color_00338e ZAfont_family" style="grid-column: 1 / span 2; margin-left:10px;">{{$t('3112')}}</span>
                        <div v-if="((configurationSteps.brakeResistor.available==true)&&(configurationSteps.powerRecuperationUnit.available==true))" style="margin-left: 10px;">
                            <div style="margin: 0px 0px 5px 0px; display:grid; grid-template-columns: 2fr 1fr; min-height:50px">
                                <div class="ZAfont_family ZAfont_size my_fontSize_1Point3 my_color_00338e">
                                    <span class="my_fontWeight_600" v-html="$t('3104')"></span>
                                    <span class="ZAfont_family">(<sup><i class="fal fa-asterisk" style="font-size:6px"></i></sup>{{$t('309')}}: {{possibleMoneySavingByUsingRecuperationUnits.calculationBasisForPossibleMoneySavingByUsingRecuperationUnits.energyCostsPerKilowattHour}} {{$t('3110', {currency: 'Cent'})}})</span>
                                    <span>:</span>
                                    <span v-if="possibleMoneySavingByUsingRecuperationUnits.possibleMoneySavingByUsingPowerRecuperationUnitsOfTheSeriesZArec > 0" class="my_fontSize_1Point4 my_fontWeight_600" style="letter-spacing:0.012em;"> {{ValueDecimalSeparator(possibleMoneySavingByUsingRecuperationUnits.possibleMoneySavingByUsingPowerRecuperationUnitsOfTheSeriesZArec)}}<sup><i class="fal fa-asterisk" style="font-size:10px"></i></sup></span>
                                    <span v-if="possibleMoneySavingByUsingRecuperationUnits.possibleMoneySavingByUsingPowerRecuperationUnitsOfTheSeriesZArec == 0" class="my_fontSize_1Point4 my_fontWeight_600" style="letter-spacing:0.012em;"> {{ValueDecimalSeparator(possibleMoneySavingByUsingRecuperationUnits.possibleMoneySavingByUsingThirdPartyPowerRecuperationUnits)}}<sup><i class="fal fa-asterisk" style="font-size:10px"></i></sup></span>
                                    <span class="my_fontWeight_600"> {{$t('3106', {currency: '€'})}}</span>
                                    <!--<div class="ZAfont_family" style="margin-top:20px; line-height:110%"><sup><i class="fal fa-asterisk" style="font-size:6px"></i></sup>{{$t('3109')}} {{possibleMoneySavingByUsingRecuperationUnits.calculationBasisForPossibleMoneySavingByUsingRecuperationUnits.standby_PowerConsumptionOfCarLightAndElevatorControlSystem}} W  |  {{$t('3111')}}: {{possibleMoneySavingByUsingRecuperationUnits.calculationBasisForPossibleMoneySavingByUsingRecuperationUnits.travel_PowerConsumptionOfCarLightAndElevatorControlSystem}} W  |  {{$t('349')}}: {{possibleMoneySavingByUsingRecuperationUnits.calculationBasisForPossibleMoneySavingByUsingRecuperationUnits.travel_LoadFactor}}  |  {{$t('309')}}: {{possibleMoneySavingByUsingRecuperationUnits.calculationBasisForPossibleMoneySavingByUsingRecuperationUnits.energyCostsPerKilowattHour}} {{$t('3110', {currency: 'Cent'})}}</div>-->
                                </div>
                                <!--<div style="display: flex; flex-direction: column;">-->
                                <div style="text-align:right">
                                    <button @click="comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown=true" class="btn my_btn_standard" style="margin: auto 0px auto 10px;">{{$t('3108')}}</button>
                                </div>
                            </div>
                        </div>
                        <div v-if="((configurationSteps.powerRecuperationUnit.available==true)&&(powerRecuperationUnit_Selection.length>0))">
                            <ul class="my_powerRecuperationUnit">
                                <li style="cursor: pointer" v-for="powerRecuperationUnit in powerRecuperationUnit_Selection" @click="change_Selected_at_powerRecuperationUnit_Selection(powerRecuperationUnit)" v-bind:class="{active: powerRecuperationUnit.selected==true}">
                                    <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point1 ZAfont_family" style="margin-right:5px">{{powerRecuperationUnit.powerRecuperationUnit.typeDesignation}}</div>
                                    <div style="display:grid; grid-template-columns: auto auto; grid-template-rows:auto auto; margin-top:5px">
                                        <div class="width1000" style="margin-right:5px">
                                            <Images :imageFilePath="powerRecuperationUnit.imagePath" :maxImageHeight="90" :maxImageWidth="150"></Images>
                                        </div>
                                        <div style="display: grid; grid-template-columns: auto auto; grid-template-rows: auto auto">
                                            <div class="my_div_minWidth_100 my_div_maginleft_5" style="margin-right:5px">
                                                <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(powerRecuperationUnit.powerRecuperationUnit.maximumRecuperationPower)}}</span> W</div>
                                                <div class="my_table_parameters_infoText">{{$t('3113')}}</div>
                                            </div>
                                            <div class="my_div_minWidth_100 my_div_maginleft_5">
                                                <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{ValueDecimalSeparator(powerRecuperationUnit.powerRecuperationUnit.maximumRecuperationCurrent)}}</span> A</div>
                                                <div class="my_table_parameters_infoText">{{$t('3114')}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div v-if="((powerRecuperationUnitsOfTheSeriesZArec_Available==true) && (thirdPartyPowerRecuperationUnits_Available==true))" style="margin-left: 10px;">
                            <a v-if="full_powerRecuperationUnit_Selection_Shown==false" @click.prevent="full_powerRecuperationUnit_Selection_Shown=true" class="ZAfont_family ZAfont_size" href="#">{{$t('3188')}}</a>
                            <a v-if="full_powerRecuperationUnit_Selection_Shown==true" @click.prevent="full_powerRecuperationUnit_Selection_Shown=false" class="ZAfont_family ZAfont_size" href="#">{{$t('3189')}}</a>
                        </div>
                        <div v-if="configurationSteps.powerRecuperationUnit.available==false" class="ZAfont_family ZAfont_size" style="margin-left: 10px;">{{$t('3270')}}</div>
                    </div>
                </div>
            </div>
            <div v-if="configurationSteps.brakeControl.available!=null" style="margin-top:50px">
                <div class="my_fontSize_1Point5 my_color_00338e my_fontWeight_600 ZAfont_family">{{$t('1016')}}</div>
                <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">{{$t('3190')}}</div>
                <div v-if="configurationSteps.brakeControl.available==true">
                    <ul class="my_brakeControl">
                        <li style="cursor: pointer" v-for="brakeControl in brakeControl_Selection" @click="change_Selected_at_brakeControl_Selection(brakeControl)" v-bind:class="{active: brakeControl.selected==true}">
                            <!--<div style="display: grid; grid-template-columns: 100px auto; grid-template-rows: auto auto; justify-items: left ">-->
                            <!--<div v-if="brakeControl.selected==true">
                            <Images :imageFilePath="brakeControl.imagePath" :maxImageHeight="90" :maxImageWidth="150"></Images>
                        </div>-->
                            <div style="display:flex; flex-wrap:wrap; width:100%; text-align:left">
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point1 ZAfont_family" style="grid-column: 1 / span 2; margin-top: 6px">{{brakeControl.brakeControl.typeDesignation}}</div>
                                    <div v-if="brakeControl.installedInTheControlCabinet == false" class="my_table_parameters_infoText">{{$t('1471')}}</div>
                                    <div v-if="brakeControl.installedInTheControlCabinet == true" class="my_table_parameters_infoText">{{$t('1472')}}</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{brakeControl.brakeControl.operatingVoltageSafetyCircuit}}</span> V</div>
                                    <div class="my_table_parameters_infoText">{{$t('3116')}}</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{brakeControl.brakeControl.ratedPowerAtOutputVoltage}}</span> W</div>
                                    <div class="my_table_parameters_infoText">{{$t('3117')}}</div>
                                </div>
                            </div>
                            <!--</div>-->
                        </li>
                    </ul>
                </div>
                <div v-if="configurationSteps.brakeControl.available==false" class="ZAfont_family ZAfont_size">{{$t('3191')}}</div>
            </div>
        </div>
        <div v-if="frame_StoredInDatabase==false" class="my_btn_nextConfigurationStep_position">
            <div v-if="controlTechnologies_Passed==true" class="my_btn_nextConfigurationStep_position">
                <button @click="furtherToSummary()" class="btn my_btn_nextConfigurationStep">{{$t('1023')}}</button>
            </div>
        </div>
        <div v-if="frame_StoredInDatabase==true" class="my_btn_nextConfigurationStep_position">
            <div v-if="controlTechnologies_Passed==true" class="my_btn_nextConfigurationStep_position">
                <button @click="furtherToFrame()" class="btn my_btn_nextConfigurationStep">{{$t('1023')}}</button>
            </div>
        </div>       
        <div class="my_modal" v-bind:class="{show: unintendedCarMovementCheck_Modal_Shown==true}" align="center">
            <div class="my_modal-dialog">
                <div class="my_modal-content" style="min-width:800px; max-height: calc(100vh - 30px)">
                    <div class="my_modal-header" style="border-bottom:none">
                        <div class="my_fontSize_1Point5 my_fontWeight_600 my_color_00338e ZAfont_family">{{$t('3096')}}</div>
                        <div>
                            <i @click="unintendedCarMovementCheck_Modal_Shown=false" class="fal fa-times fa-2x"></i>
                        </div>
                    </div>
                    <div class="my_modal-body" style="height: calc(100vh - 230px)">
                        <div v-if="loader_forLoadingAllContent_at_unintendedCarMovementCheck_Modal_Shown == true" style="text-align:center;vertical-align:central">
                            <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
                        </div>
                        <div v-else>
                            <div>
                                <div class="my_color_00338e my_fontSize_1Point5 ZAfont_family">{{$t('3023')}}</div>
                                <div style="display:grid; grid-template-rows:repeat(3, auto); grid-template-columns: repeat(3, minmax(150px, 1fr)); grid-column-gap:20px; margin-top:15px">
                                    <div style="grid-column:1; grid-row:1; align-self:end">
                                        <div class="ZAfont_family ZAfont_size">{{$t('3136')}}</div>
                                    </div>
                                    <div style="grid-column:1; grid-row:2;">
                                        <div class="input-group">
                                            <input v-model="doorZone" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="form-control" v-bind:class="{my_input_border_at_invalidInput: doorZone_ValidationResult.invalid==true}" />
                                            <div class="input-group-append">
                                                <div class="input-group-text my_input_unit ZAfont_family">mm</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="grid-column:1; grid-row:3;">
                                        <InvalidityReasons v-if="doorZone_ValidationResult.invalid==true" v-bind:validationResult="doorZone_ValidationResult"></InvalidityReasons>
                                    </div>
                                    <div style="grid-column:2; grid-row:1; align-self:end">
                                        <div class="ZAfont_family ZAfont_size">{{$t('175')}}</div>
                                    </div>
                                    <div style="grid-column:2; grid-row:2;">
                                        <div class="input-group">
                                            <input v-model="deadTime" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="form-control" v-bind:class="{my_input_border_at_invalidInput: deadTime_ValidationResult.invalid==true}" />
                                            <div class="input-group-append">
                                                <div class="input-group-text my_input_unit ZAfont_family">ms</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="grid-column:2; grid-row:3;">
                                        <InvalidityReasons v-if="deadTime_ValidationResult.invalid==true" v-bind:validationResult="deadTime_ValidationResult"></InvalidityReasons>
                                    </div>
                                    <div style="grid-column:3; grid-row:1; align-self:end">
                                        <div class="ZAfont_family ZAfont_size">{{$t('3138')}}</div>
                                    </div>
                                    <div style="grid-column:3; grid-row:2;">
                                        <div class="input-group">
                                            <input v-model="speedLimit" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: speedLimit_ValidationResult.invalid==true}" />
                                            <select v-model="speedLimit_select" class="my_input_combobox_select my_select">
                                                <option v-for="speedLimit in speedLimit_ValueList">{{speedLimit}}</option>
                                            </select>
                                            <div class="input-group-append">
                                                <button class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                                <div class="input-group-text my_input_unit ZAfont_family">m/s</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="grid-column:3; grid-row:3;">
                                        <InvalidityReasons v-if="speedLimit_ValidationResult.invalid==true" v-bind:validationResult="speedLimit_ValidationResult"></InvalidityReasons>
                                    </div>
                                    <div v-if="inputDetails_Disabled==false" style="grid-column: 1; grid-row: 4; align-self: end; margin-top: 5px">
                                        <div class="ZAfont_family ZAfont_size">{{$t('421')}}</div>
                                    </div>
                                    <div v-if="inputDetails_Disabled==false" style="grid-column:1; grid-row:5;">
                                        <div class="input-group">
                                            <input v-model="lowestShaftDoorHeight" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="form-control" v-bind:class="{my_input_border_at_invalidInput: lowestShaftDoorHeight_ValidationResult.invalid==true}" />
                                            <div class="input-group-append">
                                                <div class="input-group-text my_input_unit ZAfont_family">mm</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="grid-column:4; grid-row:1; align-self:end">
                                        <div style="display:flex; align-items:flex-end">
                                            <div>
                                                <div class="ZAfont_family ZAfont_size" v-html="$t('3137', {acceleration: 2.5})"></div>
                                            </div>
                                            <div style="padding-left: 5px">
                                                <input type="checkbox" v-model="accelerationLimitedTo2Point5MetersPerSquareSecond" />
                                            </div>
                                        </div>
                                    </div>
                                    <div style="grid-column:4; grid-row:2; align-self:end">
                                        <div style="display:flex; align-items:flex-end">
                                            <div>
                                                <div class="ZAfont_family ZAfont_size"><b>{{$t('3186')}}</b></div>
                                            </div>
                                            <div style="padding-left: 5px">
                                                <input type="checkbox" v-model="calculatedWithShortCircuitMotorBrakingCheckbox" />
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="inputDetails_Disabled==false" style="grid-column:1; grid-row:6;">
                                        <InvalidityReasons v-if="lowestShaftDoorHeight_ValidationResult.invalid==true" v-bind:validationResult="lowestShaftDoorHeight_ValidationResult"></InvalidityReasons>
                                    </div>
                                    <div v-if="inputDetails_Disabled==false" style="grid-column: 2; grid-row: 4; align-self: end; margin-top: 5px">
                                        <div class="ZAfont_family ZAfont_size">{{$t('422')}}</div>
                                    </div>
                                    <div v-if="inputDetails_Disabled==false" style="grid-column:2; grid-row:5;">
                                        <div class="input-group">
                                            <input v-model="carDoorHeight" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="form-control" v-bind:class="{my_input_border_at_invalidInput: carDoorHeight_ValidationResult.invalid==true}" />
                                            <div class="input-group-append">
                                                <div class="input-group-text my_input_unit ZAfont_family">mm</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="inputDetails_Disabled==false" style="grid-column:2; grid-row:6;">
                                        <InvalidityReasons v-if="carDoorHeight_ValidationResult.invalid==true" v-bind:validationResult="carDoorHeight_ValidationResult"></InvalidityReasons>
                                    </div>
                                    <div v-if="inputDetails_Disabled==false" style="grid-column: 3; grid-row: 4; align-self: end; margin-top: 5px">
                                        <div class="ZAfont_family ZAfont_size">{{$t('423')}}</div>
                                    </div>
                                    <div v-if="inputDetails_Disabled==false" style="grid-column:3; grid-row:5">
                                        <div class="input-group">
                                            <input v-model="carDoorApronLength" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="form-control" v-bind:class="{my_input_border_at_invalidInput: carDoorApronLength_ValidationResult.invalid==true}" />
                                            <div class="input-group-append">
                                                <div class="input-group-text my_input_unit ZAfont_family">mm</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="inputDetails_Disabled==false" style="grid-column: 3; grid-row: 6">
                                        <InvalidityReasons v-if="carDoorApronLength_ValidationResult.invalid==true" v-bind:validationResult="carDoorApronLength_ValidationResult"></InvalidityReasons>
                                    </div>
                                </div>
                            </div>
                            <div v-if="informationPermissibleDistance!=null && inputDetails_Disabled==false">
                                <span v-if="calculatedWithShortCircuitMotorBraking==false" style="color:red">{{$t('195')}} ({{$t('998')}}): {{informationPermissibleDistance}} m</span>
                                <span v-if="calculatedWithShortCircuitMotorBraking==true&&unintendedCarMovementCheck!=null&&unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Distance!=null" style="color:red">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Distance)}} m < {{$t('195')}} ({{$t('998')}}): {{informationPermissibleDistance}} m</span>
                            </div>
                            <div style="margin-top: 5px">
                                <a class="ZAfont_family ZAfont_size" href="#" @click="open_file"><b>{{$t('3373')}}</b></a>
                            </div>
                            <hr />
                            <div v-if="unintendedCarMovementCheck_Inputs_Invalid==false">
                                <div v-if="loader_forLoadingResults_at_unintendedCarMovementCheck_Modal_Shown == true" align="center">
                                    <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
                                </div>
                                <div v-else style="margin-top:20px">
                                    <div class="my_color_00338e my_fontSize_1Point5 ZAfont_family">{{$t('3139')}}</div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3140')}}</div>
                                    <div class="ZAfont_family ZAfont_size" style="background-color:#f5f5f5; padding:10px; margin-top:5px">
                                        <div class="my_color_00338e my_fontWeight_600">{{$t('3141')}}</div>
                                        <div>{{$t('3142')}}: <span class="my_fontWeight_600">{{$t(carWeightInWorstLoadCase.textKey)}}</span></div>
                                        <div>{{$t('3144')}}: <span class="my_fontWeight_600">{{$t(travelDirectionInWorstLoadCase.textKey)}}</span></div>
                                    </div>
                                    <div style="display:flex; flex-wrap:wrap; margin-top:20px">
                                        <div style="flex:1">
                                            <BrakeChart class="sticky-top"
                                                        :dt="unintendedCarMovementCheck_ChartDatasource"
                                                        :lt="unintendedCarMovementCheck_ChartLegend" />
                                        </div>
                                        <div style="flex:1" class="justify-content-center ml-5 mb-3">
                                            <ul class="ucm_select_detailedlist mb-3">
                                                <li @click="unintendedCarMovementCheck_PresentationOfResults='overview'" v-bind:class="{active: unintendedCarMovementCheck_PresentationOfResults=='overview'}">{{$t('3239')}}</li>
                                                <li @click="unintendedCarMovementCheck_PresentationOfResults='detail'" v-bind:class="{active: unintendedCarMovementCheck_PresentationOfResults=='detail'}">{{$t('3240')}}</li>
                                            </ul>
                                            <div v-if="unintendedCarMovementCheck_PresentationOfResults=='overview'">
                                                <div v-if="calculatedWithShortCircuitMotorBraking==true" class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('201')}}</div>
                                                <table class="ZAfont_size ZAfont_family mb-4 text-right w-100">
                                                    <tr class="my_background-color_e7e7e7 my_fontWeight_600">
                                                        <th class="my_table_cell text-center"><div>{{$t('3146')}}</div></th>
                                                        <th class="my_table_cell">{{$t('3151')}} a [m/s<sup>2</sup>]</th>
                                                        <th class="my_table_cell">{{$t('3152')}} s [m]</th>
                                                        <th class="my_table_cell">{{$t('3153')}} v [m/s]</th>
                                                        <th class="my_table_cell">{{$t('3154')}} t [s]</th>
                                                    </tr>
                                                    <tr>
                                                        <td class="my_table_cell" style="text-align:center"><div class="my_fontSize_1Point2 ZAfont_family">1</div></td>
                                                        <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase1Acceleration)}}</td>
                                                        <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase1Distance)}}</td>
                                                        <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase1Velocity)}}</td>
                                                        <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase1Time)}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="my_table_cell" style="text-align:center"><div class="my_fontSize_1Point2 ZAfont_family">2</div></td>
                                                        <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase2Acceleration)}}</td>
                                                        <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase2Distance)}}</td>
                                                        <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase2Velocity)}}</td>
                                                        <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase2Time)}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="my_table_cell" style="text-align:center"><div class="my_fontSize_1Point2 ZAfont_family">3</div></td>
                                                        <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase3Acceleration)}}</td>
                                                        <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase3Distance)}}</td>
                                                        <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase3Velocity)}}</td>
                                                        <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase3Time)}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="my_table_cell" style="text-align:center"><div class="my_fontSize_1Point2 ZAfont_family">4</div></td>
                                                        <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase4Acceleration)}}</td>
                                                        <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase4Distance)}}</td>
                                                        <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase4Velocity)}}</td>
                                                        <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase4Time)}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="my_table_cell" style="text-align:center"><div class="my_fontSize_1Point2 ZAfont_family">5</div></td>
                                                        <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase5Acceleration)}}</td>
                                                        <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase5Distance)}}</td>
                                                        <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase5Velocity)}}</td>
                                                        <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase5Time)}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="my_table_cell" style="text-align:center"><div class="my_fontSize_1Point2 ZAfont_family">6</div></td>
                                                        <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Acceleration)}}</td>
                                                        <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Distance)}}</td>
                                                        <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Velocity)}}</td>
                                                        <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Time)}}</td>
                                                    </tr>
                                                </table>
                                                <div v-if="calculatedWithShortCircuitMotorBraking==true">
                                                    <div class="my_fontSize_1Point2 my_color_cfab28 ZAfont_family">{{$t('199')}}</div>
                                                    <table class="ZAfont_size ZAfont_family text-right w-100">
                                                        <tr class="my_background-color_e7e7e7 my_fontWeight_600">
                                                            <th class="my_table_cell text-center">{{$t('3146')}}</th>
                                                            <th class="my_table_cell">{{$t('3151')}} a [m/s<sup>2</sup>]</th>
                                                            <th class="my_table_cell">{{$t('3152')}} s [m]</th>
                                                            <th class="my_table_cell">{{$t('3153')}} v [m/s]</th>
                                                            <th class="my_table_cell">{{$t('3154')}} t [s]</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="my_table_cell" style="text-align:center"><div class="my_fontSize_1Point2 ZAfont_family">1</div></td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase1Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase1Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase1Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase1Time)}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="my_table_cell" style="text-align:center"><div class="my_fontSize_1Point2 ZAfont_family">2</div></td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase2Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase2Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase2Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase2Time)}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="my_table_cell" style="text-align:center"><div class="my_fontSize_1Point2 ZAfont_family">3</div></td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase3Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase3Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase3Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase3Time)}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="my_table_cell" style="text-align:center"><div class="my_fontSize_1Point2 ZAfont_family">4</div></td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase4Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase4Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase4Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase4Time)}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="my_table_cell" style="text-align:center"><div class="my_fontSize_1Point2 ZAfont_family">5</div></td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase5Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase5Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase5Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase5Time)}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="my_table_cell" style="text-align:center"><div class="my_fontSize_1Point2 ZAfont_family">6</div></td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Time)}}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div v-if="unintendedCarMovementCheck_PresentationOfResults=='detail'">
                                                <div>
                                                    <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3146')}} 1</div>
                                                    <div>
                                                        <span class="my_fontWeight_600 ZAfont_family ZAfont_size">{{$t('3147')}}: </span>
                                                        <span class="ZAfont_family ZAfont_size">{{$t('3148')}}</span>
                                                    </div>
                                                    <div>
                                                        <span class="my_fontWeight_600 ZAfont_family ZAfont_size">{{$t('3155')}}: </span>
                                                        <span class="ZAfont_family ZAfont_size">{{$t('3150')}}</span>
                                                    </div>
                                                    <table class="ZAfont_size" style="text-align: right; margin-top:10px">
                                                        <tr class="my_background-color_e7e7e7 my_fontWeight_600">
                                                            <th v-if="calculatedWithShortCircuitMotorBraking==true" style="background-color:#ffffff"></th>
                                                            <th class="my_table_cell">{{$t('3151')}} a<sub>1</sub> [m/s<sup>2</sup>]</th>
                                                            <th class="my_table_cell">{{$t('3152')}} s<sub>1</sub> [m]</th>
                                                            <th class="my_table_cell">{{$t('3153')}} v<sub>1</sub> [m/s]</th>
                                                            <th class="my_table_cell">{{$t('3154')}} t<sub>1</sub> [s]</th>
                                                        </tr>
                                                        <tr>
                                                            <td v-if="calculatedWithShortCircuitMotorBraking==true" class="my_table_cell my_font-weight_700 my_color_00338e" style="text-align:center">{{$t('201')}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase1Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase1Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase1Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase1Time)}}</td>
                                                        </tr>
                                                        <tr v-if="calculatedWithShortCircuitMotorBraking==true">
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28" style="text-align:center">{{$t('199')}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase1Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase1Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase1Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase1Time)}}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <hr />
                                                <div style="margin-top:20px">
                                                    <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3146')}} 2</div>
                                                    <div>
                                                        <span class="my_fontWeight_600 ZAfont_family ZAfont_size">{{$t('3147')}}: </span>
                                                        <span class="ZAfont_family ZAfont_size">{{$t('3150')}}</span>
                                                    </div>
                                                    <div>
                                                        <span class="my_fontWeight_600 ZAfont_family ZAfont_size">{{$t('3155')}}: </span>
                                                        <span class="ZAfont_family ZAfont_size">{{$t('3158')}}</span>
                                                    </div>
                                                    <table class="ZAfont_size" style="text-align: right; margin-top: 10px">
                                                        <tr class="my_background-color_e7e7e7 my_fontWeight_600">
                                                            <th v-if="calculatedWithShortCircuitMotorBraking==true" style="background-color:#ffffff"></th>
                                                            <th class="my_table_cell">{{$t('3151')}} a<sub>2</sub> [m/s<sup>2</sup>]</th>
                                                            <th class="my_table_cell">{{$t('3152')}} s<sub>2</sub> [m]</th>
                                                            <th class="my_table_cell">{{$t('3153')}} v<sub>2</sub> [m/s]</th>
                                                            <th class="my_table_cell">{{$t('3154')}} t<sub>2</sub> [s]</th>
                                                        </tr>
                                                        <tr>
                                                            <td v-if="calculatedWithShortCircuitMotorBraking==true" class="my_table_cell my_font-weight_700 my_color_00338e" style="text-align:center">{{$t('201')}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase2Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase2Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase2Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase2Time)}}</td>
                                                        </tr>
                                                        <tr v-if="calculatedWithShortCircuitMotorBraking==true">
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28" style="text-align:center">{{$t('199')}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase2Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase2Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase2Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase2Time)}}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <hr />
                                                <div style="margin-top:20px">
                                                    <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3146')}} 3</div>
                                                    <div>
                                                        <span class="my_fontWeight_600 ZAfont_family ZAfont_size">{{$t('3147')}}: </span>
                                                        <span class="ZAfont_family ZAfont_size">{{$t('3158')}}</span>
                                                    </div>
                                                    <div>
                                                        <span class="my_fontWeight_600 ZAfont_family ZAfont_size">{{$t('3155')}}: </span>
                                                        <span class="ZAfont_family ZAfont_size">{{$t('3157')}}</span>
                                                    </div>
                                                    <table class="ZAfont_size" style="text-align: right; margin-top: 10px">
                                                        <tr class="my_background-color_e7e7e7 my_fontWeight_600">
                                                            <th v-if="calculatedWithShortCircuitMotorBraking==true" style="background-color:#ffffff"></th>
                                                            <th class="my_table_cell">{{$t('3151')}} a<sub>3</sub> [m/s<sup>2</sup>]</th>
                                                            <th class="my_table_cell">{{$t('3152')}} s<sub>3</sub> [m]</th>
                                                            <th class="my_table_cell">{{$t('3153')}} v<sub>3</sub> [m/s]</th>
                                                            <th class="my_table_cell">{{$t('3154')}} t<sub>3</sub> [s]</th>
                                                        </tr>
                                                        <tr>
                                                            <td v-if="calculatedWithShortCircuitMotorBraking==true" class="my_table_cell my_font-weight_700 my_color_00338e" style="text-align:center">{{$t('201')}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase3Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase3Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase3Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase3Time)}}</td>
                                                        </tr>
                                                        <tr v-if="calculatedWithShortCircuitMotorBraking==true">
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28" style="text-align:center">{{$t('199')}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase3Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase3Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase3Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase3Time)}}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <hr />
                                                <div style="margin-top:20px">
                                                    <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3146')}} 4</div>
                                                    <div>
                                                        <span class="my_fontWeight_600 ZAfont_family ZAfont_size">{{$t('3147')}}: </span>
                                                        <span class="ZAfont_family ZAfont_size"> {{$t('3157')}}</span>
                                                    </div>
                                                    <div>
                                                        <span class="my_fontWeight_600 ZAfont_family ZAfont_size">{{$t('3155')}}: </span>
                                                        <span class="ZAfont_family ZAfont_size">{{$t('3159')}}</span>
                                                    </div>
                                                    <table class="ZAfont_size" style="text-align: right; margin-top: 10px">
                                                        <tr class="my_background-color_e7e7e7 my_fontWeight_600">
                                                            <th v-if="calculatedWithShortCircuitMotorBraking==true" style="background-color:#ffffff"></th>
                                                            <th class="my_table_cell">{{$t('3151')}} a<sub>4</sub> [m/s<sup>2</sup>]</th>
                                                            <th class="my_table_cell">{{$t('3152')}} s<sub>4</sub> [m]</th>
                                                            <th class="my_table_cell">{{$t('3153')}} v<sub>4</sub> [m/s]</th>
                                                            <th class="my_table_cell">{{$t('3154')}} t<sub>4</sub> [s]</th>
                                                        </tr>
                                                        <tr>
                                                            <td v-if="calculatedWithShortCircuitMotorBraking==true" class="my_table_cell my_font-weight_700 my_color_00338e" style="text-align:center">{{$t('201')}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase4Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase4Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase4Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase4Time)}}</td>
                                                        </tr>
                                                        <tr v-if="calculatedWithShortCircuitMotorBraking==true">
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28" style="text-align:center">{{$t('199')}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase4Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase4Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase4Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase4Time)}}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <hr />
                                                <div style="margin-top:20px">
                                                    <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3146')}} 5</div>
                                                    <div>
                                                        <span class="my_fontWeight_600 ZAfont_family ZAfont_size">{{$t('3147')}}: </span>
                                                        <span class="ZAfont_family ZAfont_size">{{$t('3159')}}</span>
                                                    </div>
                                                    <div>
                                                        <span class="my_fontWeight_600 ZAfont_family ZAfont_size">{{$t('3155')}}: </span>
                                                        <span class="ZAfont_family ZAfont_size">{{$t('3160')}}</span>
                                                    </div>
                                                    <table class="ZAfont_size" style="text-align: right; margin-top: 10px">
                                                        <tr class="my_background-color_e7e7e7 my_fontWeight_600">
                                                            <th v-if="calculatedWithShortCircuitMotorBraking==true" style="background-color:#ffffff"></th>
                                                            <th class="my_table_cell">{{$t('3151')}} a<sub>5</sub> [m/s<sup>2</sup>]</th>
                                                            <th class="my_table_cell">{{$t('3152')}} s<sub>5</sub> [m]</th>
                                                            <th class="my_table_cell">{{$t('3153')}} v<sub>5</sub> [m/s]</th>
                                                            <th class="my_table_cell">{{$t('3154')}} t<sub>5</sub> [s]</th>
                                                        </tr>
                                                        <tr>
                                                            <td v-if="calculatedWithShortCircuitMotorBraking==true" class="my_table_cell my_font-weight_700 my_color_00338e" style="text-align:center">{{$t('201')}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase5Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase5Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase5Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase5Time)}}</td>
                                                        </tr>
                                                        <tr v-if="calculatedWithShortCircuitMotorBraking==true">
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28" style="text-align:center">{{$t('199')}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase5Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase5Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase5Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase5Time)}}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <hr />
                                                <div style="margin-top:20px">
                                                    <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3146')}} 6</div>
                                                    <div>
                                                        <span class="my_fontWeight_600 ZAfont_family ZAfont_size">{{$t('3147')}}: </span>
                                                        <span class="ZAfont_family ZAfont_size">{{$t('3160')}}</span>
                                                    </div>
                                                    <div>
                                                        <span class="my_fontWeight_600 ZAfont_family ZAfont_size">{{$t('3155')}}: </span>
                                                        <span class="ZAfont_family ZAfont_size">{{$t('3156')}}</span>
                                                    </div>
                                                    <table class="ZAfont_size" style="text-align: right; margin-top: 10px">
                                                        <tr class="my_background-color_e7e7e7 my_fontWeight_600">
                                                            <th v-if="calculatedWithShortCircuitMotorBraking==true" style="background-color:#ffffff"></th>
                                                            <th class="my_table_cell">{{$t('3151')}} a<sub>6</sub> [m/s<sup>2</sup>]</th>
                                                            <th class="my_table_cell">{{$t('3152')}} s<sub>6</sub> [m]</th>
                                                            <th class="my_table_cell">{{$t('3153')}} v<sub>6</sub> [m/s]</th>
                                                            <th class="my_table_cell">{{$t('3154')}} t<sub>6</sub> [s]</th>
                                                        </tr>
                                                        <tr>
                                                            <td v-if="calculatedWithShortCircuitMotorBraking==true" class="my_table_cell my_font-weight_700 my_color_00338e" style="text-align:center">{{$t('201')}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_00338e">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Time)}}</td>
                                                        </tr>
                                                        <tr v-if="calculatedWithShortCircuitMotorBraking==true">
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28" style="text-align:center">{{$t('199')}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Acceleration)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Distance)}}</td>
                                                            <td class="my_table_cell my_font-weight_700 my_color_cfab28">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Velocity)}}</td>
                                                            <td class="my_table_cell">{{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Time)}}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else align="center">
                                <InvalidInputs></InvalidInputs>
                            </div>
                        </div>
                    </div>
                    <div class="my_modal-footer ZAfont_family ZAfont_size" style="align-items: flex-end; justify-content: space-between">
                        <div>
                            <div v-if="unintendedCarMovementCheck_Inputs_Invalid==false">
                                <div v-if="((loader_forLoadingAllContent_at_unintendedCarMovementCheck_Modal_Shown == true)||(loader_forLoadingResults_at_unintendedCarMovementCheck_Modal_Shown == true))">
                                    <LoaderIcon v-bind:loaderSize="'xs'"></LoaderIcon>
                                </div>
                                <div v-if="((loader_forLoadingAllContent_at_unintendedCarMovementCheck_Modal_Shown == false)&&(loader_forLoadingResults_at_unintendedCarMovementCheck_Modal_Shown == false)&&(Object.keys(this.unintendedCarMovementCheck).length>0))">
                                    <div style="display:flex">
                                        <div style="margin-right:5px">
                                            <div class="my_fontWeight_600">{{$t('3194')}}: </div>
                                        </div>
                                        <div style="text-align:left">
                                            <div style="line-height:150%"><span class="my_fontSize_1Point5 my_font-weight_700"> {{ValueDecimalSeparator(unintendedCarMovementCheck.stoppingDistanceWithoutInfluenceOfTraction)}} </span><span class="my_fontWeight_600">m</span> - {{$t('3195')}}</div>
                                            <div style="line-height:150%"><span class="my_fontSize_1Point5 my_font-weight_700"> {{ValueDecimalSeparator(unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Distance)}} </span><span class="my_fontWeight_600">m</span> - {{$t('201')}}</div>
                                            <div v-if="calculatedWithShortCircuitMotorBraking==true" style="line-height:150%"><span class="my_fontSize_1Point5 my_font-weight_700"> {{ValueDecimalSeparator(unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Distance)}} </span><span class="my_fontWeight_600">m</span> - {{$t('199')}}</div>
                                        </div>
                                    </div>
                                    <div style="margin-top:10px">{{$t('3161')}}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div v-if="inverter_Selection_Shown == false">
                                <button @click="unintendedCarMovementCheck_Modal_Shown=false" class="btn my_btn_standard">{{$t('1023')}}</button>
                            </div>
                            <div v-if="inverter_Selection_Shown == true">
                                <button @click="unintendedCarMovementCheck_Modal_Shown=false" class="btn my_btn_standard">{{$t('3090')}}</button>
                                <button @click="unintendedCarMovementCheck_Modal_Continue=true" class="btn my_btn_standard" style="margin-left: 5px">{{$t('1023')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my_modal" v-bind:class="{show: comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown==true}" align="center">
            <div class="my_modal-dialog" style="min-width:800px;">
                <div class="my_modal-content" style="max-height: calc(100vh - 30px)">
                    <div class="my_modal-header" style="border-bottom:none">
                        <div class="my_fontSize_1Point5 my_fontWeight_600 my_color_00338e ZAfont_family">{{$t('3162')}}</div>
                        <div>
                            <span @click="hide_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal('close')" class="fal fa-times fa-2x"></span>
                        </div>
                    </div>
                    <div class="my_modal-body" style="height: calc(100vh - 160px)">
                        <div>
                            <div class="my_fontSize_1Point4 my_color_00338e ZAfont_family">{{$t('3023')}}</div>
                            <div style="display:grid; grid-template-rows:repeat(3, auto) ;grid-template-columns: repeat(4, minmax(120px, 1fr)); grid-column-gap:20px; align-items:stretch">
                                <div style="grid-column:1; grid-row:1; align-self:end">
                                    <div class="ZAfont_family ZAfont_size">{{$t('3119')}}</div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3120')}}</div>
                                </div>
                                <div style="grid-column:1; grid-row:2">
                                    <div class="input-group">
                                        <input class="form-control" v-bind:placeholder="this.$i18n.i18next.t('3173')" v-model="standby_PowerConsumptionOfCarLightAndElevatorControlSystem" v-bind:class="{my_input_border_at_invalidInput: standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult.invalid==true}" />
                                        <div class="input-group-append">
                                            <div class="input-group-text my_input_unit ZAfont_family">W</div>
                                        </div>
                                    </div>
                                </div>
                                <div style="grid-column:1; grid-row:3">
                                    <InvalidityReasons v-if="standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult.invalid==true" v-bind:validationResult="standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult"></InvalidityReasons>
                                </div>
                                <div style="grid-column:2; grid-row:1; align-self:end">
                                    <div class="ZAfont_family ZAfont_size">{{$t('3121')}}</div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3120')}}</div>
                                </div>
                                <div style="grid-column:2; grid-row:2">
                                    <div class="input-group">
                                        <input class="form-control" v-bind:placeholder="this.$i18n.i18next.t('3173')" v-model="travel_PowerConsumptionOfCarLightAndElevatorControlSystem" v-bind:class="{my_input_border_at_invalidInput: travel_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult.invalid==true}" />
                                        <div class="input-group-append">
                                            <div class="input-group-text my_input_unit ZAfont_family">W</div>
                                        </div>
                                    </div>
                                </div>
                                <div style="grid-column:2; grid-row:3">
                                    <InvalidityReasons v-if="travel_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult.invalid==true" v-bind:validationResult="travel_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult"></InvalidityReasons>
                                </div>
                                <div style="grid-column:3; grid-row:1; align-self:end">
                                    <div style="display:flex; align-items:flex-end">
                                        <div>
                                            <div class="ZAfont_family ZAfont_size">{{$t('3122')}}:</div>
                                        </div>
                                        <div style="padding-left: 5px">
                                            <input type="checkbox" v-model="travel_LoadFactor_Calculated" />
                                        </div>
                                    </div>
                                </div>
                                <div style="grid-column:3; grid-row:2">
                                    <div v-if="travel_LoadFactor_Calculated == false" class="input-group">
                                        <input class="form-control" v-bind:placeholder="this.$i18n.i18next.t('3173')" v-model="travel_LoadFactor" v-bind:class="{my_input_border_at_invalidInput: travel_LoadFactor_ValidationResult.invalid==true}" />
                                        <div class="input-group-append">
                                            <div class="input-group-text my_input_unit ZAfont_family">-</div>
                                        </div>
                                    </div>
                                </div>
                                <div style="grid-column:3; grid-row:3">
                                    <InvalidityReasons v-if="travel_LoadFactor_ValidationResult.invalid==true" v-bind:validationResult="travel_LoadFactor_ValidationResult"></InvalidityReasons>
                                </div>
                                <div style="grid-column:4; grid-row:1; align-self:end">
                                    <div class="ZAfont_family ZAfont_size">{{$t('309')}}</div>
                                </div>
                                <div style="grid-column:4; grid-row:2">
                                    <div class="input-group">
                                        <input class="form-control" v-bind:placeholder="this.$i18n.i18next.t('3173')" v-model="electricityCosts" v-bind:class="{my_input_border_at_invalidInput: electricityCosts_ValidationResult.invalid==true}" />
                                        <div class="input-group-append">
                                            <div class="input-group-text my_input_unit ZAfont_family">{{$t('3110', {currency: 'Cent'})}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div style="grid-column:4; grid-row:3">
                                    <InvalidityReasons v-if="electricityCosts_ValidationResult.invalid==true" v-bind:validationResult="electricityCosts_ValidationResult"></InvalidityReasons>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div v-if="loader_forLoadingAllContent_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown" style="text-align:center;vertical-align:central">
                            <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
                        </div>
                        <div v-else>
                            <div style="margin-top:20px">
                                <div class="my_fontSize_1Point4 my_color_00338e ZAfont_family">{{$t('3139')}}</div>
                                <table v-if="selected_comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit!=null" style="width:100%">
                                    <tr class="ZAfont_family">
                                        <td colspan="2" class="my_table_column"></td>
                                        <td rowspan="2" class="my_table_cell my_table_thirdColumn" style="border-top:none">
                                            <div class="my_color_00338e my_fontSize_1Point3" style="margin-bottom:5px">{{$t('3163')}}</div>
                                            <select v-model="comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit_TypeDesignation" class="my_select" style="width:100%">
                                                <option v-for="powerRecuperationUnit in powerRecuperationUnit_Selection" v-bind:value="powerRecuperationUnit.powerRecuperationUnit.typeDesignation">{{powerRecuperationUnit.powerRecuperationUnit.typeDesignation}}</option>
                                            </select>
                                            <div class="ZAfont_family ZAfont_size" style="background-color:#f5f5f5; padding:0.75rem; margin-top:10px">
                                                <div class="my_color_00338e my_fontWeight_600">{{$t('3177')}}</div>
                                                <div style="display:flex; flex-wrap:wrap; justify-content:space-between;">
                                                    <div>{{$t('3113')}}:</div>
                                                    <div>{{ValueDecimalSeparator(selected_comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit.powerRecuperationUnit.maximumRecuperationPower)}} W</div>
                                                    <div>{{$t('3114')}}:</div>
                                                    <div>{{ValueDecimalSeparator(selected_comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit.powerRecuperationUnit.maximumRecuperationCurrent)}} A</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="ZAfont_family" style="height:2.3rem">
                                        <td class="my_table_cell my_table_firstColumn my_color_00338e my_fontSize_1Point3" style="border-left:none; border-right:none; border-top:none; vertical-align:bottom">
                                            <div v-if="((loader_forLoadingResults_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown == false)&&(Object.keys(this.comparisonOfPossibilitiesToConvertRegenerativeEnergy).length>0))">{{$t('3130')}}</div>
                                        </td>
                                        <td class="my_table_cell my_table_secondColumn my_color_00338e my_fontSize_1Point3" style="border-top:none; vertical-align:bottom">{{$t('388')}}</td>
                                    </tr>
                                </table>
                                <div v-if="energyEfficiencyRating_Inputs_Invalid==false">
                                    <div v-if="loader_forLoadingResults_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown == true" align="center">
                                        <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
                                    </div>
                                    <div v-if="((loader_forLoadingResults_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown == false)&&(Object.keys(this.comparisonOfPossibilitiesToConvertRegenerativeEnergy).length>0)&&(selected_comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit!=null))">
                                        <table style="width:100%">
                                            <tr class="ZAfont_family ZAfont_size">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('3131')}} [mWh/kg/m]</td>
                                                <td class="my_table_cell my_table_secondColumn">{{ValueDecimalSeparator(comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor.specificEnergyDemand)}}</td>
                                                <td class="my_table_cell my_table_thirdColumn">{{ValueDecimalSeparator(comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit.specificEnergyDemand)}}</td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size" style="font-weight:bold">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('366')}} (VDI 4707)</td>
                                                <td class="my_table_cell my_table_secondColumn">{{comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor.energyEfficiencyClass}}</td>
                                                <td class="my_table_cell my_table_thirdColumn">{{comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit.energyEfficiencyClass}}</td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size" style="font-weight:bold">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('365')}} [€]</td>
                                                <td class="my_table_cell my_table_secondColumn">{{ValueDecimalSeparator(comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor.electricityPricePerYear)}}</td>
                                                <td class="my_table_cell my_table_thirdColumn">{{ValueDecimalSeparator(comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit.electricityPricePerYear)}}</td>
                                            </tr>
                                            <tr>
                                                <td class="my_table_cell my_table_firstColumn my_color_00338e my_fontSize_1Point3 ZAfont_family" style="border-left:none">{{$t('370')}}</td>
                                                <td colspan="2" class="my_table_cell"></td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('3124')}} [W]</td>
                                                <td colspan="2" class="my_table_cell my_table_column">{{ValueDecimalSeparator(standby_PowerConsumptionOfCarLightAndElevatorControlSystem)}}</td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('3125')}} [W]</td>
                                                <td colspan="2" class="my_table_cell my_table_column">{{ValueDecimalSeparator(energyEfficiencyRating.inverter.powerConsumption)}}</td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('3126')}} [W]</td>
                                                <td class="my_table_cell my_table_secondColumn">-</td>
                                                <td class="my_table_cell my_table_thirdColumn">{{ValueDecimalSeparator(selected_comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit.powerRecuperationUnit.powerConsumption)}}</td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('3127')}} [W]</td>
                                                <td class="my_table_cell my_table_secondColumn">{{ValueDecimalSeparator(comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor.standbyStandbyByDemand)}}</td>
                                                <td class="my_table_cell my_table_thirdColumn">{{ValueDecimalSeparator(comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit.standbyStandbyByDemand)}}</td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size" style="font-weight:bold">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('3167')}} (VDI 4707)</td>
                                                <td class="my_table_cell my_table_secondColumn">{{comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor.standbyEnergyDemandClass}}</td>
                                                <td class="my_table_cell my_table_thirdColumn">{{comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit.standbyEnergyDemandClass}}</td>
                                            </tr>
                                            <tr>
                                                <td class="my_table_cell my_table_firstColumn ZAfont_family my_color_00338e my_fontSize_1Point3" style="border-left:none">{{$t('3168')}}</td>
                                                <td colspan="2" class="my_table_cell"></td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('349')}}</td>
                                                <td colspan="2" class="my_table_cell my_table_column">{{travel_LoadFactor}}</td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('359')}} [Wh]</td>
                                                <td class="my_table_cell my_table_secondColumn">{{ValueDecimalSeparator(comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor.travelEnergyConsumptionAtReferenceTrip)}}</td>
                                                <td class="my_table_cell my_table_thirdColumn">{{ValueDecimalSeparator(comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit.travelEnergyConsumptionAtReferenceTrip)}}</td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('3124')}} [W]</td>
                                                <td colspan="2" class="my_table_cell my_table_column">{{ValueDecimalSeparator(travel_PowerConsumptionOfCarLightAndElevatorControlSystem)}}</td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('3128')}} [W]</td>
                                                <td colspan="2" class="my_table_cell my_table_column">{{ValueDecimalSeparator(energyEfficiencyRating.elevatorMotor_Machine.powerConsumption)}}</td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('3129')}} [W]</td>
                                                <td colspan="2" class="my_table_cell my_table_column">{{ValueDecimalSeparator(energyEfficiencyRating.elevatorMotor_Machine.loadPower)}}</td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('363')}} [mWh/(kg*m)]</td>
                                                <td class="my_table_cell my_table_secondColumn">{{ValueDecimalSeparator(comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor.travelSpecificTravelDemand)}}</td>
                                                <td class="my_table_cell my_table_thirdColumn">{{ValueDecimalSeparator(comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit.travelSpecificTravelDemand)}}</td>
                                            </tr>
                                            <tr class="ZAfont_family ZAfont_size" style="font-weight:bold">
                                                <td class="my_table_cell my_table_firstColumn" style="border-left:none">{{$t('3167')}} (VDI 4707)</td>
                                                <td class="my_table_cell my_table_secondColumn">{{comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor.travelEnergyDemandClass}}</td>
                                                <td class="my_table_cell my_table_thirdColumn">{{comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit.travelEnergyDemandClass}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div v-else>
                                    <InvalidInputs></InvalidInputs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my_modal-footer" style="min-width:800px; align-items: flex-end; justify-content: space-between">
                        <div>
                            <div v-if="energyEfficiencyRating_Inputs_Invalid==false">
                                <div v-if="((loader_forLoadingAllContent_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown == true)||(loader_forLoadingResults_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown == true))">
                                    <LoaderIcon v-bind:loaderSize="'xs'"></LoaderIcon>
                                </div>
                                <div v-if="((loader_forLoadingAllContent_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown == false)&&(loader_forLoadingResults_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown == false)&&(Object.keys(this.comparisonOfPossibilitiesToConvertRegenerativeEnergy).length > 0))" class="my_fontWeight_600 ZAfont_size ZAfont_family">
                                    <span style="max-height:25px">{{$t('3166')}}: </span>
                                    <span class="my_fontSize_1Point5 my_font-weight_700">{{ValueDecimalSeparator((comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor.electricityPricePerYear - comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit.electricityPricePerYear).toFixed(2))}} </span>
                                    <span>{{$t('3106', {currency: '€'})}}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style="display:flex; align-items:flex-end">
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('1316')}}:</div>
                                </div>
                                <div style="padding-left: 5px">
                                    <input type="checkbox" v-model="print_EnergyCalculation" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div v-if="energyEfficiencyRating_Inputs_Invalid==false">
                                <button @click="hide_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal('brake resistor')" class="btn my_btn_standard" style="margin-right:10px">{{$t('3164')}}</button>
                                <button @click="hide_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal('power recuperation unit')" class="btn my_btn_standard_blue">{{$t('3165')}}</button>
                            </div>
                            <div v-else>
                                <button @click="hide_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal('close')" class="btn my_btn_standard">{{$t('3090')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div style="position:fixed; bottom:55px;left:5px">
        <button @click="openHelpFile" style="width:25px; height:25px; border-radius: 50%; background-color: #00338e; border: none; color: white; text-align: center; text-decoration: none; display: inline-block; font-size: 14px; cursor: pointer; font-weight: bold">i</button>
    </div>-->
    </div>
</template>

<script>
    import router from '../../../router';
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import LoaderIcon from '../../loader/LoaderIcon';
    import BrakeChart from './BrakeChart.vue'
    import InvalidInputs from '../../configuration/InvalidInputs'
    import Images from '../../configuration/Images';

    export default {
        name: 'ControlTechnologies',
        components: {
            InvalidityReasons,
            LoaderIcon,
            BrakeChart,
            InvalidInputs,
            Images,
        },
        data() {
            return {
                // unintendedCarMovementCheck
                unintendedCarMovementCheck_ChartLegendTable: [
                    { value: "V1", name: this.$t('201'), color: "#00338e" },
                    { value: "V2", name: this.$t('199'), color: "#cfab28" }
                ],
            }
        },
        computed: {
            sapProfile: {
                get() {
                    return this.$store.state.configurations.sap;
                }
            },

            print_EnergyCalculation:
            {
                get() {
                    return this.$store.state.controlTechnologies.printInDocument;
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/changed_energyEfficiencyRating_PrintInDocument_storeAction', value)
                }
            },

            controlTechnologies_Passed: {
                get() {
                    if (this.$store.state.configurations.navigation.controlTechnologies == 'passed') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            frame_StoredInDatabase: {
                get() {
                    if (this.$store.state.configurations.configurationSteps.frame != null && this.$store.state.configurations.configurationSteps.frame.storedInDatabase == true) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            configurationOverviewsId: {
                get() {
                    return this.$store.state.configurations.configurationOverviewsId;
                },
            },
            configurationSteps: {
                get() {
                    return this.$store.state.configurations.configurationSteps;
                },
            },
            loaderModal_Shown: {
                get() {
                    return this.$store.state.container.loaderModal_Shown;
                },
            },

            inverter_Filter_Shown: {
                get() {
                    return this.$store.state.controlTechnologies.inverter_Filter_Shown;
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_inverter_Filter_Shown_storeAction', value)
                }
            },

            // inverter
            inverter_Selection_Shown: {
                get() {
                    return this.$store.state.controlTechnologies.inverter_Selection_Shown
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_inverter_Selection_Shown_storeAction', value)
                }
            },
            inverter_Selection: {
                get() {
                    if ((this.$store.state.controlTechnologies.inverter_Data.hasOwnProperty('inverter_Selection')) && (Array.isArray(this.$store.state.controlTechnologies.inverter_Data.inverter_Selection))) {
                        if (this.inverter_Selection_Filtered == false) {
                            if (this.$store.state.controlTechnologies.inverter_Data.inverter_Selection.length > 0) {
                                return this.$store.state.controlTechnologies.inverter_Data.inverter_Selection.slice(0, this.inverter_Selection_lastIndex);
                            }
                            else if (this.$store.state.controlTechnologies.inverter_Data.hasOwnProperty('thirdPartyInverter')) {
                                this.change_Selected_at_thirdPartyInverter();
                                return this.$store.state.controlTechnologies.inverter_Data.inverter_Selection.slice(0, this.inverter_Selection_lastIndex);
                            }
                            else {
                                return this.$store.state.controlTechnologies.inverter_Data.inverter_Selection.slice(0, this.inverter_Selection_lastIndex);
                            }
                        }
                        else if (this.inverter_Selection_Filtered == true) {
                            return this.filtered_inverter_Selection.slice(0, this.inverter_Selection_lastIndex)
                        }
                    }
                    else {
                        return [];
                    }
                }
            },
            inverter_Selection_fullLength: {
                get() {
                    if ((this.$store.state.controlTechnologies.inverter_Data.hasOwnProperty('inverter_Selection')) && (Array.isArray(this.$store.state.controlTechnologies.inverter_Data.inverter_Selection))) {
                        return this.$store.state.controlTechnologies.inverter_Data.inverter_Selection.length;
                    }
                    else {
                        return 0;
                    }
                }
            },
            inverter_Selected: {
                get() {
                    if ((this.$store.state.controlTechnologies.inverter_Data.hasOwnProperty('inverter_Selection')) && (Array.isArray(this.$store.state.controlTechnologies.inverter_Data.inverter_Selection))) {
                        return this.$store.state.controlTechnologies.inverter_Data.inverter_Selection.some(i => i.selected == true);
                    }
                },
            },
            selected_inverter: {
                get() {
                    if ((this.$store.state.controlTechnologies.inverter_Data.hasOwnProperty('inverter_Selection')) && (Array.isArray(this.$store.state.controlTechnologies.inverter_Data.inverter_Selection))) {
                        return this.$store.state.controlTechnologies.inverter_Data.inverter_Selection.find(i => i.selected == true)
                    }
                }
            },

            thirdPartyInverter: {
                get() {
                    if (this.$store.state.controlTechnologies.inverter_Data.hasOwnProperty('thirdPartyInverter')) {
                        return this.$store.state.controlTechnologies.inverter_Data.thirdPartyInverter;

                    }
                },
            },
            thirdPartyInverter_Selected: {
                get() {
                    if (this.$store.state.controlTechnologies.inverter_Data.hasOwnProperty('thirdPartyInverter')) {
                        return this.$store.state.controlTechnologies.inverter_Data.thirdPartyInverter.selected;
                    }
                    else {
                        return false;
                    }
                },
            },

            inverter_Selection_lastIndex: {
                get() {
                    return this.$store.state.controlTechnologies.inverter_Selection_lastIndex;
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_inverter_Selection_lastIndex_storeAction', value)
                }
            },
            filtered_inverter_Selection: {
                get() {
                    return this.$store.state.controlTechnologies.filtered_inverter_Selection;
                },
            },
            inverter_Selection_Filtered: {
                get() {
                    return this.$store.state.controlTechnologies.inverter_Selection_Filtered;
                },
            },

            // inverter filter
            elevatorMotor_Encoder_Selection: {
                get() {
                    if ((this.$store.state.driveTechnologies.elevatorMotor_Encoder_Data.hasOwnProperty('elevatorMotor_Encoder_Selection')) && (Array.isArray(this.$store.state.driveTechnologies.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection))) {
                        return this.$store.state.driveTechnologies.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection;
                    }
                    else {
                        return [];
                    }
                },
            },
            elevatorMotor_Encoder_TypeDesignation: {
                get() {
                    const selected_elevatorMotor_Encoder = this.elevatorMotor_Encoder_Selection.find(i => i.selected == true);
                    if (selected_elevatorMotor_Encoder != null) {
                        return selected_elevatorMotor_Encoder.elevatorMotor_Encoder.typeDesignation;
                    }
                    else {
                        return selected_elevatorMotor_Encoder;
                    }
                },
                set(value) {
                    const index = this.elevatorMotor_Encoder_Selection.findIndex(i => i.elevatorMotor_Encoder.typeDesignation == value);
                    if (index > -1) {
                        this.$store.dispatch('driveTechnologies/change_userPreference_affected_selected_elevatorMotor_Encoder_storeAction', false)
                        this.$store.dispatch('driveTechnologies/change_elevatorMotor_Encoder_Selection_Shown_storeAction', true)
                        this.$store.dispatch('driveTechnologies/checkIf_changesAt_elevatorMotor_Encoder_changes_configurationFlow_storeAction', index)
                    }
                    else {
                        // error
                    }
                },
            },

            inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet: {
                get() {
                    return this.$store.state.controlTechnologies.inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet;
                },
            },

            inverter_FilterCriterion_inverter_Series_Selection: {
                get() {
                    return this.$store.state.controlTechnologies.inverter_FilterCriterion_inverter_Series_Selection;
                },
            },
            arrayOf_active_inverter_FilterCriteria: {
                get() {
                    return this.$store.state.controlTechnologies.arrayOf_active_inverter_FilterCriteria;
                },
            },


            // unintendedCarMovementCheck
            unintendedCarMovementCheck_Modal_Continue: {
                get() {
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/check_unintendedCarMovementCheck_Valid_storeAction', value);
                }
            },
            unintendedCarMovementCheck_Modal_Shown: {
                get() {
                    return this.$store.state.controlTechnologies.unintendedCarMovementCheck_Modal_Shown;
                },
                set(value) {
                    if (value == false) {
                        this.$store.dispatch('controlTechnologies/check_unintendedCarMovementCheck_Valid_storeAction', false);
                    }
                    this.$store.dispatch('controlTechnologies/change_unintendedCarMovementCheck_Modal_Shown_storeAction', value);
                }
            },
            loader_forLoadingAllContent_at_unintendedCarMovementCheck_Modal_Shown: {
                get() {
                    return this.$store.state.controlTechnologies.loader_forLoadingAllContent_at_unintendedCarMovementCheck_Modal_Shown;
                },
            },
            loader_forLoadingResults_at_unintendedCarMovementCheck_Modal_Shown: {
                get() {
                    return this.$store.state.controlTechnologies.loader_forLoadingResults_at_unintendedCarMovementCheck_Modal_Shown;
                },
            },

            speedLimit_ValueList: {
                get() {
                    if (this.$store.state.controlTechnologies.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded == true) {
                        if (this.$store.state.configurations.language == 'de') {
                            const valueList = this.$store.state.controlTechnologies.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.speedLimit_ValueList.map(num => {
                                return String(num).replace(".", ",");
                            });
                            return valueList;
                        }
                        else {
                            const valueList = this.$store.state.controlTechnologies.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.speedLimit_ValueList.map(num => {
                                return String(num).replace(".", ",");
                            });
                            return valueList;
                        }
                    }
                    return this.$store.state.controlTechnologies.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.speedLimit_ValueList;
                }
            },

            doorZone: {
                get() {
                    if (this.$store.state.controlTechnologies.doorZone.hasOwnProperty('value') == true) {
                        return this.$store.state.controlTechnologies.doorZone.value;
                    }
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_doorZone_storeAction', value)
                }
            },
            doorZone_ValidationResult: {
                get() {
                    return this.$store.state.controlTechnologies.doorZone;
                }
            },
            deadTime: {
                get() {
                    if (this.$store.state.controlTechnologies.deadTime.hasOwnProperty('value') == true) {
                        return this.$store.state.controlTechnologies.deadTime.value;
                    }
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_deadTime_storeAction', value)
                }
            },
            deadTime_ValidationResult: {
                get() {
                    return this.$store.state.controlTechnologies.deadTime;
                },
            },
            accelerationLimitedTo2Point5MetersPerSquareSecond: {
                get() {
                    return this.$store.state.controlTechnologies.accelerationLimitedTo2Point5MetersPerSquareSecond;
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_accelerationLimitedTo2Point5MetersPerSquareSecond_storeAction', value)
                }
            },
            calculatedWithShortCircuitMotorBrakingCheckbox: {
                get() {
                    return this.$store.state.controlTechnologies.calculatedWithShortCircuitMotorBraking;
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_calculatedWithShortCircuitMotorBraking_storeAction', value)
                }
            },
            speedLimit: {
                get() {
                    if (this.$store.state.controlTechnologies.speedLimit.hasOwnProperty('value') == true) {
                        const localDecimal = { value: this.$store.state.controlTechnologies.speedLimit.value, country: this.$store.state.configurations.language, }
                        return this.$get_decimalValueToLocaleString(localDecimal);
                    }
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'speedLimit' }
                    this.$store.dispatch('controlTechnologies/set_decimalValue', localDecimal);
                }
            },
            speedLimit_select: {
                get() {
                    if (this.$store.state.controlTechnologies.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.hasOwnProperty('speedLimit_ValueList')) {
                        if (this.speedLimit_ValueList.includes(this.speedLimit)) {
                            const localDecimal = { value: this.speedLimit, country: this.$store.state.configurations.language, }
                            return this.$get_decimalValueToLocaleString(localDecimal);
                        }
                    }
                },
                set(value) {
                    this.speedLimit = value;
                }
            },
            speedLimit_ValidationResult: {
                get() {
                    return this.$store.state.controlTechnologies.speedLimit;
                },
            },
            inputDetails_Disabled: {
                get() {
                    //if (this.$store.state.controlTechnologies.unintendedCarMovementCheck != null &&  this.$store.state.controlTechnologies.unintendedCarMovementCheck.inputDetails_Disabled != null) {
                    //    return this.$store.state.controlTechnologies.unintendedCarMovementCheck.inputDetails_Disabled;
                    //}
                    //else {
                    //    return false;
                    //}
                    return this.$store.state.controlTechnologies.unintendedCarMovementCheck_Input_Disabled;
                }
            },
            lowestShaftDoorHeight: {
                get() {
                    if (this.$store.state.controlTechnologies.lowestShaftDoorHeight.hasOwnProperty('value') == true) {
                        return this.$store.state.controlTechnologies.lowestShaftDoorHeight.value;
                    }
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_lowestShaftDoorHeight_storeAction', value)
                }
            },
            lowestShaftDoorHeight_ValidationResult: {
                get() {
                    return this.$store.state.controlTechnologies.lowestShaftDoorHeight;
                }
            },
            carDoorHeight: {
                get() {
                    if (this.$store.state.controlTechnologies.carDoorHeight.hasOwnProperty('value') == true) {
                        return this.$store.state.controlTechnologies.carDoorHeight.value;
                    }
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_carDoorHeight_storeAction', value)
                }
            },
            carDoorHeight_ValidationResult: {
                get() {
                    return this.$store.state.controlTechnologies.carDoorHeight;
                }
            },
            carDoorApronLength: {
                get() {
                    if (this.$store.state.controlTechnologies.carDoorApronLength.hasOwnProperty('value') == true) {
                        return this.$store.state.controlTechnologies.carDoorApronLength.value;
                    }
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_carDoorApronLength_storeAction', value)
                }
            },
            carDoorApronLength_ValidationResult: {
                get() {
                    return this.$store.state.controlTechnologies.carDoorApronLength;
                }
            },
            informationPermissibleDistance: {
                get() {
                    return this.$store.state.controlTechnologies.informationPermissibleDistance;
                }
            },
            unintendedCarMovementCheck_Inputs_Invalid: {
                get() {
                    return this.$store.state.controlTechnologies.unintendedCarMovementCheck_Inputs_Invalid;
                },
            },

            carWeightInWorstLoadCase: {
                get() {
                    return this.$store.state.controlTechnologies.carWeightInWorstLoadCase;
                },
            },
            travelDirectionInWorstLoadCase: {
                get() {
                    return this.$store.state.controlTechnologies.travelDirectionInWorstLoadCase;
                },
            },

            unintendedCarMovementCheck_ChartDatasource: {
                get() {
                    var unintendedCarMovementCheckChart = [{ s: 0, V1: 0 }, { s: this.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase1Distance, V1: this.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase1Velocity }, { s: this.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase2Distance, V1: this.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase2Velocity }, { s: this.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase3Distance, V1: this.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase3Velocity }, { s: this.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase4Distance, V1: this.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase4Velocity }, { s: this.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase5Distance, V1: this.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase5Velocity }, { s: this.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Distance, V1: this.unintendedCarMovementCheck.withoutShortCircuitMotorBrakingPhase6Velocity }]

                    if (this.calculatedWithShortCircuitMotorBraking == true) {
                        unintendedCarMovementCheckChart = unintendedCarMovementCheckChart.concat([{ s: 0, V2: 0 }, { s: this.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase1Distance, V2: this.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase1Velocity }, { s: this.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase2Distance, V2: this.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase2Velocity }, { s: this.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase3Distance, V2: this.unintendedCarMovementCheck.WithoutShortCircuitMotorBrakingPhase3Velocity }, { s: this.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase4Distance, V2: this.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase4Velocity }, { s: this.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase5Distance, V2: this.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase5Velocity }, { s: this.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Distance, V2: this.unintendedCarMovementCheck.atShortCircuitMotorBrakingPhase6Velocity }])
                    }
                    return unintendedCarMovementCheckChart
                }
            },
            unintendedCarMovementCheck_ChartLegend: {
                get() {
                    if (this.calculatedWithShortCircuitMotorBraking == false) {
                        return this.unintendedCarMovementCheck_ChartLegendTable.slice(0, 1);
                    }
                    else if (this.calculatedWithShortCircuitMotorBraking == true) {
                        return this.unintendedCarMovementCheck_ChartLegendTable;
                    }
                }
            },

            unintendedCarMovementCheck_PresentationOfResults: {
                get() {
                    return this.$store.state.controlTechnologies.unintendedCarMovementCheck_PresentationOfResults;
                },
                set(value) {
                    if (value != this.unintendedCarMovementCheck_PresentationOfResults) {
                        this.$store.dispatch('controlTechnologies/change_unintendedCarMovementCheck_PresentationOfResults_storeAction', value);
                    }
                }
            },

            calculatedWithShortCircuitMotorBraking: {
                get() {
                    return this.$store.state.controlTechnologies.calculatedWithShortCircuitMotorBraking;
                },
            },

            unintendedCarMovementCheck: {
                get() {
                    return this.$store.state.controlTechnologies.unintendedCarMovementCheck;
                },
            },

            // brakeResistor / powerRecuperationUnit
            productTypeForConversionOfRegenerativeEnergy: {
                get() {
                    return this.$store.state.controlTechnologies.productTypeForConversionOfRegenerativeEnergy;
                },
                set(value) {
                    if (value != this.productTypeForConversionOfRegenerativeEnergy) {
                        this.$store.dispatch('controlTechnologies/change_productTypeForConversionOfRegenerativeEnergy_storeAction', value)
                    }
                }
            },

            // brakeResistor
            brakeResistor_Selection: {
                get() {
                    if ((this.$store.state.controlTechnologies.brakeResistor_Data.hasOwnProperty('brakeResistor_Selection')) && (Array.isArray(this.$store.state.controlTechnologies.brakeResistor_Data.brakeResistor_Selection))) {
                        if (this.$store.state.controlTechnologies.brakeResistor_Data.brakeResistor_Selection.findIndex(i => i.selected == true) > 0 && this.$store.state.controlTechnologies.full_brakeResistor_Selection_Shown == false) {
                            this.$store.dispatch('controlTechnologies/change_full_brakeResistor_Selection_Shown_storeAction', true)
                        }
                        if (this.$store.state.controlTechnologies.full_brakeResistor_Selection_Shown == true) {
                            return this.$store.state.controlTechnologies.brakeResistor_Data.brakeResistor_Selection;
                        }
                        else {
                            return this.$store.state.controlTechnologies.brakeResistor_Data.brakeResistor_Selection.slice(0, 1)
                        }
                    }
                    else {
                        return [];
                    }
                }
            },
            brakeResistor_Selected: {
                get() {
                    return this.brakeResistor_Selection.some(i => i.selected == true);
                },
            },
            selected_brakeResistor: {
                get() {
                    return this.brakeResistor_Selection.find(i => i.selected == true);
                },
            },

            full_brakeResistor_Selection_Shown: {
                get() {
                    return this.$store.state.controlTechnologies.full_brakeResistor_Selection_Shown;
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_full_brakeResistor_Selection_Shown_storeAction', value)
                }
            },

            // powerRecuperationUnit
            powerRecuperationUnit_Selection: {
                get() {
                    if ((this.$store.state.controlTechnologies.powerRecuperationUnit_Data.hasOwnProperty('powerRecuperationUnit_Selection')) && (Array.isArray(this.$store.state.controlTechnologies.powerRecuperationUnit_Data.powerRecuperationUnit_Selection))) {
                        if (this.$store.state.controlTechnologies.powerRecuperationUnit_Data.powerRecuperationUnit_Selection.findIndex(i => i.selected == true) > 0 && this.$store.state.controlTechnologies.full_powerRecuperationUnit_Selection_Shown == false) {
                            this.$store.dispatch('controlTechnologies/change_full_powerRecuperationUnit_Selection_Shown_storeAction', true)
                        }
                        if (this.$store.state.controlTechnologies.comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown == true) {
                            return this.$store.state.controlTechnologies.powerRecuperationUnit_Data.powerRecuperationUnit_Selection;
                        }
                        if (this.$store.state.controlTechnologies.full_powerRecuperationUnit_Selection_Shown == true) {
                            return this.$store.state.controlTechnologies.powerRecuperationUnit_Data.powerRecuperationUnit_Selection;
                        }
                        else {
                            return this.$store.state.controlTechnologies.powerRecuperationUnit_Data.powerRecuperationUnit_Selection.slice(0, 1);
                        }
                    }
                    else {
                        return [];
                    }
                },
            },

            powerRecuperationUnit_Selected: {
                get() {
                    return this.powerRecuperationUnit_Selection.some(i => i.selected == true);
                },
            },
            selected_powerRecuperationUnit: {
                get() {
                    return this.powerRecuperationUnit_Selection.find(i => i.selected == true)
                },
            },

            full_powerRecuperationUnit_Selection_Shown: {
                get() {
                    return this.$store.state.controlTechnologies.full_powerRecuperationUnit_Selection_Shown;
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_full_powerRecuperationUnit_Selection_Shown_storeAction', value)
                }
            },

            powerRecuperationUnitsOfTheSeriesZArec_Available: {
                get() {
                    return this.$store.state.controlTechnologies.powerRecuperationUnit_Data.powerRecuperationUnitsOfTheSeriesZArec_Available;
                },
            },
            thirdPartyPowerRecuperationUnits_Available: {
                get() {
                    return this.$store.state.controlTechnologies.powerRecuperationUnit_Data.thirdPartyPowerRecuperationUnits_Available;
                },
            },
            possibleMoneySavingByUsingRecuperationUnits: {
                get() {
                    return this.$store.state.controlTechnologies.powerRecuperationUnit_Data.possibleMoneySavingByUsingRecuperationUnits;
                },
            },

            // brakeControl
            brakeControl_Selection: {
                get() {
                    if ((this.$store.state.controlTechnologies.brakeControl_Data.hasOwnProperty('brakeControl_Selection')) && (Array.isArray(this.$store.state.controlTechnologies.brakeControl_Data.brakeControl_Selection))) {
                        return this.$store.state.controlTechnologies.brakeControl_Data.brakeControl_Selection;
                    }
                    else {
                        return [];
                    }
                },
            },


            // energyEfficiencyRating
            loader_forLoadingResults_at_energyEfficiencyRating_Shown: {
                get() {
                    return this.$store.state.controlTechnologies.loader_forLoadingResults_at_energyEfficiencyRating_Shown;
                },
            },

            standby_PowerConsumptionOfCarLightAndElevatorControlSystem: {
                get() {
                    const localDecimal = { value: this.$store.state.controlTechnologies.standby_PowerConsumptionOfCarLightAndElevatorControlSystem.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'standby_PowerConsumptionOfCarLightAndElevatorControlSystem' }
                    this.$store.dispatch('controlTechnologies/set_decimalValue', localDecimal);
                }
            },
            standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult: {
                get() {
                    return this.$store.state.controlTechnologies.standby_PowerConsumptionOfCarLightAndElevatorControlSystem;
                },
            },
            travel_PowerConsumptionOfCarLightAndElevatorControlSystem: {
                get() {
                    const localDecimal = { value: this.$store.state.controlTechnologies.travel_PowerConsumptionOfCarLightAndElevatorControlSystem.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'travel_PowerConsumptionOfCarLightAndElevatorControlSystem' }
                    this.$store.dispatch('controlTechnologies/set_decimalValue', localDecimal);
                }
            },
            travel_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult: {
                get() {
                    return this.$store.state.controlTechnologies.travel_PowerConsumptionOfCarLightAndElevatorControlSystem;
                },
            },
            travel_LoadFactor_Calculated: {
                get() {
                    return this.$store.state.controlTechnologies.travel_LoadFactor_Calculated;

                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_travel_LoadFactor_Calculated_storeAction', value);
                }
            },
            travel_LoadFactor: {
                get() {
                    return this.$store.state.controlTechnologies.travel_LoadFactor.value;
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_travel_LoadFactor_storeAction', value);
                }
            },
            travel_LoadFactor_ValidationResult: {
                get() {
                    return this.$store.state.controlTechnologies.travel_LoadFactor;
                },
            },
            electricityCosts: {
                get() {
                    const localDecimal = { value: this.$store.state.controlTechnologies.electricityCosts.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'electricityCosts' }
                    this.$store.dispatch('controlTechnologies/set_decimalValue', localDecimal);
                }
            },
            electricityCosts_ValidationResult: {
                get() {
                    return this.$store.state.controlTechnologies.electricityCosts;
                },
            },
            energyEfficiencyRating_Inputs_Invalid: {
                get() {
                    return this.$store.state.controlTechnologies.energyEfficiencyRating_Inputs_Invalid;
                }
            },

            energyEfficiencyRating: {
                get() {
                    return this.$store.state.controlTechnologies.energyEfficiencyRating;
                },
            },


            // comparisonOfPossibilitiesToConvertRegenerativeEnergy
            comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown: {
                get() {
                    return this.$store.state.controlTechnologies.comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown;
                },
                set(value) {
                    this.$store.dispatch('controlTechnologies/change_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown_storeAction', value);
                }
            },
            loader_forLoadingAllContent_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown: {
                get() {
                    return this.$store.state.controlTechnologies.loader_forLoadingAllContent_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown;
                },
            },
            loader_forLoadingResults_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown: {
                get() {
                    return this.$store.state.controlTechnologies.loader_forLoadingResults_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown;
                },
            },

            selected_comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit: {
                get() {
                    return this.powerRecuperationUnit_Selection.find(i => i.selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy == true);
                },
            },
            comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit_TypeDesignation: {
                get() {
                    if (this.selected_comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit != null) {
                        return this.selected_comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit.powerRecuperationUnit.typeDesignation;
                    }
                },
                set(value) {
                    const firstIndex_whichMeetCondition = this.powerRecuperationUnit_Selection.findIndex(i => i.powerRecuperationUnit.typeDesignation == value);
                    if (firstIndex_whichMeetCondition > -1) {
                        this.$store.dispatch('controlTechnologies/change_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_at_powerRecuperationUnit_Selection_storeAction', firstIndex_whichMeetCondition);
                        if (this.energyEfficiencyRating_Inputs_Invalid == false) {
                            this.$store.dispatch('controlTechnologies/get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations_storeAction');
                        }
                    }
                    else {
                        // error
                    }
                }
            },
            comparisonOfPossibilitiesToConvertRegenerativeEnergy: {
                get() {
                    return this.$store.state.controlTechnologies.comparisonOfPossibilitiesToConvertRegenerativeEnergy;
                }
            },
        },

        created() {
            this.changeWindowSize();
            if (this.configurationOverviewsId != null) {
                this.$store.dispatch('configurations/check_toDos_at_controlTechnologies_storeAction')
            }
            else {
                this.$store.dispatch('configurations/toDos_after_routeSlug_UpdatedOrEntered_storeAction', 'controlTechnologies')
            }
        },

        methods: {
            openHelpFile() {
                this.$store.dispatch('configurations/openControlTechnologyHelpFile_storeAction', this.$store.state.configurations.language);
            },
            changeWindowSize() {
                const width = document.documentElement.clientWidth;
                if (width <= 1500) {
                    this.$store.dispatch('controlTechnologies/change_inverter_Filter_Shown_storeAction', false)
                }
                else {
                    this.$store.dispatch('controlTechnologies/change_inverter_Filter_Shown_storeAction', true)
                }
            },
            open_file() {
                this.$store.dispatch('controlTechnologies/openUcmFile_storeAction', this.$store.state.configurations.language)
            },

            ValueDecimalSeparator(value) {
                if (value != null) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                }
                return value;
            },

            furtherToSummary() {              
                router.push({ params: { slug: 'summary' } });
            },
            furtherToFrame() {
                router.push({ params: { slug: 'frames' } });
            },
            furtherToCables() {
                router.push({ params: { slug: 'cables' } });
            },

            // inverter
            get_inverter_Informations() {
                this.$store.dispatch('controlTechnologies/get_inverter_Informations_storeAction');
            },

            show_less_inverters_fromSelection() {
                if (this.inverter_Selection_lastIndex < 10) {
                    this.inverter_Selection_lastIndex = this.inverter_Selection_lastIndex - (this.inverter_Selection_lastIndex - 5);
                }
                else if (this.inverter_Selection_lastIndex >= 10) {
                    this.inverter_Selection_lastIndex = this.inverter_Selection_lastIndex - 5;
                }
            },
            show_full_inverter_Selection() {
                if (this.inverter_Selection_Filtered == false) {
                    this.inverter_Selection_lastIndex = this.inverter_Selection_fullLength;
                }
                else {
                    this.inverter_Selection_lastIndex = this.filtered_inverter_Selection.length;
                }
            },
            show_more_inverters_fromSelection() {
                this.inverter_Selection_lastIndex = this.inverter_Selection_lastIndex + 5;
                if (this.inverter_Selection_Filtered == false) {
                    if (this.inverter_Selection_lastIndex > this.inverter_Selection_fullLength) {
                        this.inverter_Selection_lastIndex = this.inverter_Selection_fullLength;
                    }
                }
                else {
                    if (this.inverter_Selection_lastIndex > this.filtered_inverter_Selection.length) {
                        this.inverter_Selection_lastIndex = this.filtered_inverter_Selection.length;
                    }
                }
            },

            async change_unintendedCarMovementCheckCheck_Modal_Continue(value) {
                if (value == true) {
                    const firstIndex_whichMeetConditions = this.$store.state.controlTechnologies.inverter_Data.inverter_Selection.findIndex(i => (i.inverter.blockNumber == this.$store.state.controlTechnologies.inverter_forWhich_unintendedCarMovement_isToBeMade.inverter.blockNumber))
                    await this.$store.dispatch('controlTechnologies/change_unintendedCarMovementCheck_Modal_Shown_storeAction', false);
                    if (firstIndex_whichMeetConditions > -1) {
                        const inverter = this.$store.state.controlTechnologies.inverter_Data.inverter_Selection[firstIndex_whichMeetConditions];
                        this.change_Selected_at_inverter_Selection(inverter);
                    }

                }
            },

            change_Selected_at_inverter_Selection(value) {
                this.$store.dispatch('controlTechnologies/checkIf_changesAt_inverter_changes_configurationFlow_storeAction', value);
            },
            change_Selected_at_thirdPartyInverter() {
                this.$store.dispatch('controlTechnologies/checkIf_changesAt_thirdPartyInverter_changes_configurationFlow_storeAction');
            },

            // inverter filter
            change_Selected_atElementOf_inverter_FilterCriterion_inverter_Series_Selection(inverter_Series) {
                this.$store.dispatch('controlTechnologies/change_Selected_atElementOf_inverter_FilterCriterion_inverter_Series_Selection_storeAction', inverter_Series);
            },

            remove_allElementsAt_arrayof_active_inverter_FilterCriteria() {
                this.$store.dispatch('controlTechnologies/remove_allElementsAt_arrayof_active_inverter_FilterCriteria_storeAction')
            },

            // unintendedCarMovement
            get_unintendedCarMovementCheck_Informations_for_inverter(inverter) {
                this.$store.dispatch('controlTechnologies/checkIf_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded_storeAction', inverter)
            },
            get_unintendedCarMovementCheck_Data_fromItemOf_inverter_Selection(inverter) {
                this.$store.dispatch('controlTechnologies/checkIf_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded2_storeAction', inverter)
            },
            get_unintendedCarMovementCheck_Informations_for_thirdPartyInverter() {
                this.$store.dispatch('controlTechnologies/checkIf_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded3_storeAction')
            },
            get_unintendedCarMovementCheck_Data_fromItemOf_thirdPartyInverter() {
                this.$store.dispatch('controlTechnologies/checkIf_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded4_storeAction')
            },

            // comparisonOfPossibilitiesToConvertRegenerativeEnergy
            hide_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal(closingOption) {
                this.$store.dispatch('controlTechnologies/hide_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_storeAction', closingOption)
            },

            // brakeResistor
            change_Selected_at_brakeResistor_Selection(value) {
                if ((this.selected_brakeResistor == null) || (value.brakeResistor.blockNumber != this.selected_brakeResistor.brakeResistor.blockNumber)) {
                    const firstIndex_whichMeetCondition = this.brakeResistor_Selection.findIndex(i => i.brakeResistor.blockNumber == value.brakeResistor.blockNumber);
                    if (firstIndex_whichMeetCondition > -1) {
                        this.$store.dispatch('controlTechnologies/checkIf_changesAt_brakeResistor_changes_configurationFlow_storeAction', firstIndex_whichMeetCondition);
                    }
                    else {
                        // error
                    }
                }
            },

            // powerRecuperationUnit
            change_Selected_at_powerRecuperationUnit_Selection(value) {
                if ((this.selected_powerRecuperationUnit == null) || (value.powerRecuperationUnit.blockNumber != this.selected_powerRecuperationUnit.powerRecuperationUnit.blockNumber)) {
                    const firstIndex_whichMeetCondition = this.powerRecuperationUnit_Selection.findIndex(i => i.powerRecuperationUnit.blockNumber == value.powerRecuperationUnit.blockNumber);
                    if (firstIndex_whichMeetCondition > -1) {
                        this.$store.dispatch('controlTechnologies/checkIf_changesAt_powerRecuperationUnit_changes_configurationFlow_storeAction', firstIndex_whichMeetCondition);
                    }
                    else {
                        // error
                    }
                }
            },

            // brakeControl
            change_Selected_at_brakeControl_Selection(value) {
                this.$store.dispatch('controlTechnologies/checkIf_changesAt_brakeControl_changes_configurationFlow_storeAction', value);
            },
            mounted() {
                window.addEventListener('resize', this.changeWindowSize);
            },
            unmounted() {
                window.removeEventListener('resize', this.changeWindowSize);
            },
        },
    }
</script>

<style>
    .my_inverter_filter {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_inverter_filter li {
            border: 2px solid #00338e;
            color: #00338e;
            display: inline-block;
            padding: 9px;
            width: 50%;
            text-align: center;
            font-weight: 600;
        }

        .my_inverter_filter .active {
            background-color: #00338e;
            color: #ffffff;
        }

    .my_brakeResistorOrPowerRecuperationUnit {
        background-color: transparent;
        border-bottom: 1px solid #e3e3e3;
        list-style: none;
        margin: 0;
        padding: 0;
    }

        .my_brakeResistorOrPowerRecuperationUnit li {
            display: inline-block;
            font-weight: 600;
            padding: 5px 10px 5px 10px;
        }

        .my_brakeResistorOrPowerRecuperationUnit .active {
            border-bottom: 5px solid #6491c8;
            color: #00338e;
        }

    .my_brakeResistor_Column {
        grid-column: 1;
        margin-right: 10px;
        width: 100%;
    }

    .my_brakeResistor {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_brakeResistor li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            margin-bottom: 10px;
            width: calc(100% - 10px);
            /*margin-right: 10px;*/
        }

        /*            .my_brakeResistor li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_brakeResistor li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }*/

        .my_brakeResistor .active {
            border: 2px solid blue;
        }

    .my_powerRecuperationUnit_Column {
        grid-column: 2;
        width: 100%;
    }

    .my_powerRecuperationUnit {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_powerRecuperationUnit li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            margin-bottom: 10px;
            width: calc(100% - 10px);
            margin-left: 10px;
        }

        /*            .my_powerRecuperationUnit li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_powerRecuperationUnit li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }*/

        .my_powerRecuperationUnit .active {
            border: 2px solid blue;
        }

    .my_brakeControl {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_brakeControl li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            margin-bottom: 10px;
        }

            .my_brakeControl li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_brakeControl li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_brakeControl .active {
            border: 2px solid blue;
        }

    #table_energyCalculation tr:nth-child(odd):not(:first-child) {
        background-color: #e3e3e3;
    }

    #my_engine_table_parameters {
        font-size: 1.4rem;
        font-weight: 600;
    }

    #my_engine_table_parameters_slash {
        font-size: 1.0rem;
    }

    #my_engine_table_parameters_infoText {
        font-size: 0.75rem;
    }

    my_color_cfab28 {
        color: #cfab28;
    }

    .ucm_select_detailedlist {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .ucm_select_detailedlist li {
            border: 2px solid #6491c8;
            color: #6491c8;
            font-weight: 500;
            font-size: 1rem;
            display: inline-block;
            padding: 3px;
            text-align: center;
            margin-bottom: 10px;
            width: 50%;
            cursor: pointer;
        }

        .ucm_select_detailedlist .active {
            background-color: #6491c8;
            color: #ffffff;
        }
</style>